import React from 'react';
import { User } from 'lucide-react';
import type { QualityIssue } from '../../../../types/quality';

interface UserAnalysisProps {
  issues: QualityIssue[];
}

export function UserAnalysis({ issues }: UserAnalysisProps) {
  const userStats = issues.reduce((acc, issue) => {
    if (issue.assignedTo) {
      acc[issue.assignedTo] = (acc[issue.assignedTo] || 0) + 1;
    }
    return acc;
  }, {} as Record<string, number>);

  const sortedUsers = Object.entries(userStats)
    .sort(([, a], [, b]) => b - a);

  const total = issues.length;

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <h3 className="text-lg font-medium text-gray-900 mb-6">Issues by User</h3>
      
      <div className="space-y-4">
        {sortedUsers.map(([user, count]) => (
          <div key={user} className="space-y-1">
            <div className="flex items-center justify-between text-sm">
              <div className="flex items-center space-x-2">
                <User size={16} className="text-purple-500" />
                <span className="text-gray-700">{user}</span>
              </div>
              <span className="text-gray-600">{count} issues</span>
            </div>
            <div className="w-full bg-gray-100 rounded-full h-2">
              <div
                className="bg-purple-500 h-2 rounded-full transition-all duration-300"
                style={{ width: `${(count / total) * 100}%` }}
              />
            </div>
          </div>
        ))}

        {sortedUsers.length === 0 && (
          <p className="text-center text-gray-500">No assigned issues found</p>
        )}
      </div>
    </div>
  );
}