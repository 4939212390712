import React from 'react';
import { QualityDashboard } from './QualityDashboard';
import type { QualityIssue } from '../../../types/quality';

interface DashboardProps {
  issues: QualityIssue[];
}

export function Dashboard({ issues }: DashboardProps) {
  return (
    <div className="p-6 bg-gray-50 rounded-lg">
      <QualityDashboard issues={issues} />
    </div>
  );
}