import React, { useState } from 'react';
import { X } from 'lucide-react';

interface DateRangePickerProps {
  initialRange?: { start: Date; end: Date } | null;
  onSelect: (range: { start: Date; end: Date } | null) => void;
  onCancel: () => void;
}

export function DateRangePicker({ initialRange, onSelect, onCancel }: DateRangePickerProps) {
  const [startDate, setStartDate] = useState(initialRange?.start ? formatDate(initialRange.start) : '');
  const [endDate, setEndDate] = useState(initialRange?.end ? formatDate(initialRange.end) : '');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (startDate && endDate) {
      onSelect({
        start: new Date(startDate),
        end: new Date(endDate)
      });
    }
  };

  const handleClearDates = () => {
    onSelect(null);
  };

  return (
    <div className="bg-white rounded-lg shadow-xl p-6 max-w-md w-full">
      <div className="flex justify-between items-center mb-6">
        <h3 className="text-lg font-medium text-gray-900">Select Date Range</h3>
        <button
          onClick={onCancel}
          className="p-2 text-gray-400 hover:text-gray-500 transition-colors"
        >
          <X size={20} />
        </button>
      </div>

      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Start Date
            </label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              max={endDate || undefined}
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              End Date
            </label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              min={startDate || undefined}
              max={formatDate(new Date())}
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              required
            />
          </div>
        </div>

        <div className="flex justify-between pt-4">
          <button
            type="button"
            onClick={handleClearDates}
            className="text-gray-600 hover:text-gray-900"
          >
            All Time
          </button>
          <div className="space-x-3">
            <button
              type="button"
              onClick={onCancel}
              className="px-4 py-2 text-gray-700 hover:text-gray-900 transition-colors"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
            >
              Apply
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

function formatDate(date: Date): string {
  return date.toISOString().split('T')[0];
}