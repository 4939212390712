import React from 'react';
import { WelcomeHeader } from './WelcomeHeader';
import { AssemblyStats } from './stats/AssemblyStats';
import { HarnessStats } from './stats/HarnessStats';
import { QualityMetrics } from './stats/QualityMetrics';
import { useQualityIssues } from '../../hooks/useQualityIssues';
import type { Assembly, Harness } from '../../types';

interface WelcomeDashboardProps {
  assemblies: Assembly[];
  harnesses: Harness[];
  currentUser: any;
}

export function WelcomeDashboard({ 
  assemblies = [], 
  harnesses = [], 
  currentUser 
}: WelcomeDashboardProps) {
  const { issues } = useQualityIssues();

  return (
    <div className="space-y-6">
      <WelcomeHeader currentUser={currentUser} />
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <AssemblyStats assemblies={assemblies} />
        <HarnessStats harnesses={harnesses} />
        <QualityMetrics issues={issues} />
      </div>
    </div>
  );
}