import React from 'react';
import { IssueActions } from './IssueActions';
import type { QualityIssue } from '../../../types/quality';

interface ResolutionStepActionsProps {
  stepNumber: number;
  stepTitle: string;
  issues: QualityIssue[];
  onUpdateIssue?: (issue: QualityIssue) => void;
}

export function ResolutionStepActions({ 
  stepNumber, 
  stepTitle, 
  issues,
  onUpdateIssue 
}: ResolutionStepActionsProps) {
  const issuesWithStepActions = issues.filter(issue => {
    const step = issue.resolutionSteps.find(s => s.step === stepNumber);
    return step?.actionSteps && step.actionSteps.length > 0;
  });

  if (issuesWithStepActions.length === 0) return null;

  return (
    <div className="p-6">
      <h3 className="text-lg font-medium text-gray-900 mb-4">
        Step {stepNumber}: {stepTitle}
      </h3>
      <div className="space-y-4">
        {issuesWithStepActions.map(issue => (
          <IssueActions
            key={issue.id}
            issue={issue}
            stepNumber={stepNumber}
            onUpdateIssue={onUpdateIssue}
          />
        ))}
      </div>
    </div>
  );
}