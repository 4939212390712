import React, { useState } from 'react';
import { Plus, KanbanSquare, List, BarChart2 } from 'lucide-react';
import { QualityIssuesGrid } from './QualityIssuesGrid';
import { QualityIssueDetail } from './QualityIssueDetail';
import { QualityIssueForm } from './forms/QualityIssueForm';
import { KanbanBoard } from './board/KanbanBoard';
import { ActionListView } from './actionList/ActionListView';
import { Dashboard } from './dashboard/Dashboard';
import { useQualityIssues } from '../../hooks/useQualityIssues';
import { LoadingScreen } from '../common/LoadingScreen';
import { ErrorScreen } from '../common/ErrorScreen';
import { OfflineIndicator } from '../common/OfflineIndicator';
import type { QualityIssue } from '../../types/quality';

export function QualityDashboard() {
  const { 
    issues, 
    isLoading, 
    error, 
    isOffline, 
    saveIssue, 
    updateIssueStatus,
    deleteIssue 
  } = useQualityIssues();
  
  const [selectedIssue, setSelectedIssue] = useState<QualityIssue | null>(null);
  const [showForm, setShowForm] = useState(false);
  const [editingIssue, setEditingIssue] = useState<QualityIssue | null>(null);
  const [viewMode, setViewMode] = useState<'grid' | 'kanban' | 'action-list' | 'dashboard'>('grid');

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (error) {
    return <ErrorScreen error={error} />;
  }

  const handleEditIssue = (issue: QualityIssue) => {
    const issueToEdit = {
      ...issue,
      resolutionSteps: issue.resolutionSteps ? [...issue.resolutionSteps] : []
    };
    setEditingIssue(issueToEdit);
    setShowForm(true);
  };

  const handleSubmitIssue = async (formData: Omit<QualityIssue, 'id' | 'createdAt' | 'updatedAt'>) => {
    try {
      if (editingIssue) {
        await saveIssue({
          ...formData,
          id: editingIssue.id,
          createdAt: editingIssue.createdAt,
          updatedAt: new Date()
        });
        
        if (selectedIssue?.id === editingIssue.id) {
          setSelectedIssue({
            ...formData,
            id: editingIssue.id,
            createdAt: editingIssue.createdAt,
            updatedAt: new Date()
          });
        }
      } else {
        await saveIssue(formData);
      }
      
      setShowForm(false);
      setEditingIssue(null);
    } catch (err) {
      console.error('Failed to save issue:', err);
    }
  };

  return (
    <div className="flex-1 overflow-hidden">
      <div className="h-full flex flex-col p-6 space-y-4">
        {/* Action Bar */}
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-bold text-gray-900">Quality Issues</h2>
          <div className="flex items-center space-x-3">
            <button
              onClick={() => setViewMode('dashboard')}
              className={`flex items-center space-x-2 px-4 py-2 ${
                viewMode === 'dashboard'
                  ? 'bg-blue-50 text-blue-600'
                  : 'text-gray-600 hover:text-gray-900'
              } border border-gray-300 rounded-lg hover:bg-gray-50 transition-colors`}
            >
              <BarChart2 size={20} />
              <span>Dashboard</span>
            </button>
            <button
              onClick={() => setViewMode('action-list')}
              className={`flex items-center space-x-2 px-4 py-2 ${
                viewMode === 'action-list'
                  ? 'bg-blue-50 text-blue-600'
                  : 'text-gray-600 hover:text-gray-900'
              } border border-gray-300 rounded-lg hover:bg-gray-50 transition-colors`}
            >
              <List size={20} />
              <span>Action List</span>
            </button>
            <button
              onClick={() => setViewMode(viewMode === 'grid' ? 'kanban' : 'grid')}
              className="flex items-center space-x-2 px-4 py-2 text-gray-700 hover:text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-50 transition-colors"
            >
              <KanbanSquare size={20} />
              <span>View as {viewMode === 'grid' ? 'Kanban' : 'Grid'}</span>
            </button>
            <button
              onClick={() => setShowForm(true)}
              className="flex items-center space-x-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
            >
              <Plus size={20} />
              <span>New Issue</span>
            </button>
          </div>
        </div>

        {/* Main Content */}
        <div className="flex-1 min-h-0">
          {viewMode === 'grid' ? (
            <QualityIssuesGrid
              issues={issues}
              onSelectIssue={setSelectedIssue}
            />
          ) : viewMode === 'kanban' ? (
            <KanbanBoard
              issues={issues}
              onUpdateIssue={saveIssue}
              onUpdateStatus={updateIssueStatus}
              onDeleteIssue={deleteIssue}
              onEditIssue={handleEditIssue}
            />
          ) : viewMode === 'action-list' ? (
            <ActionListView 
              issues={issues}
              onUpdateIssue={saveIssue}
            />
          ) : (
            <Dashboard issues={issues} />
          )}
        </div>
      </div>

      {/* Modals */}
      {(showForm || selectedIssue) && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-6 z-50 overflow-y-auto">
          <div className="bg-white rounded-lg shadow-xl w-full max-w-6xl max-h-[90vh] overflow-hidden flex flex-col">
            {showForm ? (
              <QualityIssueForm
                issue={editingIssue}
                onSubmit={handleSubmitIssue}
                onCancel={() => {
                  setShowForm(false);
                  setEditingIssue(null);
                }}
              />
            ) : selectedIssue && (
              <QualityIssueDetail
                issue={selectedIssue}
                onClose={() => setSelectedIssue(null)}
                onEdit={handleEditIssue}
                onDelete={async () => {
                  if (window.confirm('Are you sure you want to delete this issue?')) {
                    await deleteIssue(selectedIssue.id);
                    setSelectedIssue(null);
                  }
                }}
              />
            )}
          </div>
        </div>
      )}

      {isOffline && <OfflineIndicator />}
    </div>
  );
}