import React from 'react';
import { ActionItem } from './ActionItem';
import type { QualityIssue } from '../../../types/quality';

interface IssueActionsProps {
  issue: QualityIssue;
  stepNumber: number;
  onUpdateIssue?: (issue: QualityIssue) => void;
}

export function IssueActions({ issue, stepNumber, onUpdateIssue }: IssueActionsProps) {
  const step = issue.resolutionSteps.find(s => s.step === stepNumber);
  if (!step?.actionSteps || step.actionSteps.length === 0) return null;

  const handleUpdateAction = (actionId: string, text: string) => {
    if (!onUpdateIssue) return;

    const updatedIssue: QualityIssue = {
      ...issue,
      resolutionSteps: issue.resolutionSteps.map(s =>
        s.step === stepNumber
          ? {
              ...s,
              actionSteps: s.actionSteps.map(a =>
                a.id === actionId ? { ...a, text } : a
              ),
            }
          : s
      ),
      updatedAt: new Date(),
    };

    onUpdateIssue(updatedIssue);
  };

  const handleDeleteAction = (actionId: string) => {
    if (!onUpdateIssue) return;

    const updatedIssue: QualityIssue = {
      ...issue,
      resolutionSteps: issue.resolutionSteps.map(s =>
        s.step === stepNumber
          ? {
              ...s,
              actionSteps: s.actionSteps.filter(a => a.id !== actionId),
            }
          : s
      ),
      updatedAt: new Date(),
    };

    onUpdateIssue(updatedIssue);
  };

  const handleToggleAction = (actionId: string) => {
    if (!onUpdateIssue) return;

    const updatedIssue: QualityIssue = {
      ...issue,
      resolutionSteps: issue.resolutionSteps.map(s =>
        s.step === stepNumber
          ? {
              ...s,
              actionSteps: s.actionSteps.map(a =>
                a.id === actionId ? { ...a, isComplete: !a.isComplete } : a
              ),
            }
          : s
      ),
      updatedAt: new Date(),
    };

    onUpdateIssue(updatedIssue);
  };

  return (
    <div className="bg-white rounded-lg border border-gray-200 overflow-hidden">
      <div className="px-4 py-3 bg-gray-50 border-b border-gray-200">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <span className="font-medium text-gray-900">#{issue.issueNumber}</span>
            <span className={`px-2 py-0.5 text-xs rounded-full ${
              issue.status === 'resolved' 
                ? 'bg-green-100 text-green-800' 
                : issue.status === 'in-progress'
                ? 'bg-blue-100 text-blue-800'
                : 'bg-yellow-100 text-yellow-800'
            }`}>
              {issue.status}
            </span>
          </div>
          <span className="text-sm text-gray-600">{issue.title}</span>
        </div>
      </div>
      
      <div className="p-4 space-y-2">
        {step.actionSteps.map(action => (
          <ActionItem
            key={action.id}
            action={action}
            onUpdate={handleUpdateAction}
            onDelete={handleDeleteAction}
            onToggle={handleToggleAction}
          />
        ))}
      </div>
    </div>
  );
}