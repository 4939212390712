import { useState } from 'react';
import { useSensors, useSensor, PointerSensor } from '@dnd-kit/core';
import type { DragEndEvent, DragStartEvent } from '@dnd-kit/core';
import type { QualityIssue } from '../../../types/quality';

export function useDragAndDrop(
  onUpdateStatus: (issueId: string, newStatus: QualityIssue['status']) => void
) {
  const [activeId, setActiveId] = useState<string | null>(null);
  
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  const handleDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id as string);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    setActiveId(null);
    
    if (!over) return;

    const issueId = active.id as string;
    const newStatus = over.id as QualityIssue['status'];
    
    const issue = active.data.current as QualityIssue;
    if (!issue || issue.status === newStatus) return;

    onUpdateStatus(issueId, newStatus);
  };

  return {
    activeId,
    sensors,
    handleDragStart,
    handleDragEnd
  };
}