import React from 'react';
import { CheckCircle2, Circle, Trash2 } from 'lucide-react';
import type { QualityIssue } from '../../../types/quality';

interface ActionStepsListProps {
  actionSteps: QualityIssue['resolutionSteps'][0]['actionSteps'];
  onToggle: (actionStepId: string) => void;
  onDelete: (actionStepId: string) => void;
}

export function ActionStepsList({ actionSteps, onToggle, onDelete }: ActionStepsListProps) {
  if (!actionSteps?.length) {
    return (
      <div className="text-center py-4 text-gray-500">
        <p>No action steps added yet</p>
      </div>
    );
  }

  return (
    <div className="space-y-2">
      {actionSteps.map(actionStep => (
        <div 
          key={actionStep.id}
          className="flex items-center space-x-2 p-2 bg-white rounded-lg border border-gray-200"
        >
          <button
            onClick={() => onToggle(actionStep.id)}
            className="flex-shrink-0"
          >
            {actionStep.isComplete ? (
              <CheckCircle2 className="text-green-500" size={20} />
            ) : (
              <Circle className="text-gray-400" size={20} />
            )}
          </button>
          <span className={`flex-1 text-sm ${actionStep.isComplete ? 'text-gray-500 line-through' : 'text-gray-900'}`}>
            {actionStep.text}
          </span>
          <button
            onClick={() => onDelete(actionStep.id)}
            className="text-gray-400 hover:text-red-500 transition-colors"
          >
            <Trash2 size={16} />
          </button>
        </div>
      ))}
    </div>
  );
}