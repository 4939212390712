import React from 'react';
import { CheckCircle2, AlertTriangle, X } from 'lucide-react';
import type { AssemblyCSVData, ValidationResult } from '../../types/csv';

interface ImportPreviewProps {
  data: AssemblyCSVData[];
  validation: ValidationResult;
  onConfirm: () => void;
  onCancel: () => void;
}

export function ImportPreview({ 
  data, 
  validation, 
  onConfirm, 
  onCancel 
}: ImportPreviewProps) {
  const hasErrors = validation.errors.length > 0;
  const hasWarnings = validation.warnings.length > 0;

  // Create lookup maps for warnings and errors
  const warningMap = new Map<string, string[]>();
  const errorMap = new Map<string, string[]>();

  validation.warnings.forEach(warning => {
    const match = warning.match(/^Row \d+ \((.*?)\):/);
    if (match) {
      const assemblyName = match[1];
      const warnings = warningMap.get(assemblyName) || [];
      warnings.push(warning.split(': ')[1]);
      warningMap.set(assemblyName, warnings);
    }
  });

  validation.errors.forEach(error => {
    const match = error.match(/^Row \d+ \((.*?)\):/);
    if (match) {
      const assemblyName = match[1];
      const errors = errorMap.get(assemblyName) || [];
      errors.push(error.split(': ')[1]);
      errorMap.set(assemblyName, errors);
    }
  });

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-6 z-50">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-6xl max-h-[80vh] flex flex-col">
        <div className="flex-none px-6 py-4 border-b flex justify-between items-center">
          <h2 className="text-xl font-bold text-gray-900">
            Preview CSV Import ({data.length} assemblies)
          </h2>
          <button
            onClick={onCancel}
            className="p-2 text-gray-400 hover:text-gray-500 transition-colors"
          >
            <X size={20} />
          </button>
        </div>

        <div className="flex-1 flex overflow-hidden">
          {/* Main Table */}
          <div className="flex-1 overflow-auto p-6 border-r">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                    Assembly Name
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                    Child Assemblies
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                    Procedures
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {data.map((row, index) => {
                  const warnings = warningMap.get(row.assemblyName) || [];
                  const errors = errorMap.get(row.assemblyName) || [];

                  return (
                    <tr 
                      key={index}
                      className={`${
                        errors.length > 0 
                          ? 'bg-red-50' 
                          : warnings.length > 0 
                          ? 'bg-yellow-50' 
                          : ''
                      }`}
                    >
                      <td className="px-6 py-4">
                        <span className="text-sm font-medium text-gray-900">
                          {row.assemblyName}
                        </span>
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-500">
                        {row.childAssemblies.join(', ')}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-500">
                        {row.procedures.join(', ')}
                      </td>
                      <td className="px-6 py-4 text-sm">
                        {errors.length > 0 ? (
                          <span className="text-red-600">Error</span>
                        ) : warnings.length > 0 ? (
                          <span className="text-yellow-600">Warning</span>
                        ) : (
                          <span className="text-green-600">Valid</span>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          {/* Validation Panel */}
          {(hasWarnings || hasErrors) && (
            <div className="w-80 flex-none overflow-auto bg-gray-50 p-4">
              <div className="space-y-4">
                {hasErrors && (
                  <div>
                    <h3 className="text-sm font-medium text-red-800 mb-2 flex items-center space-x-2">
                      <AlertTriangle size={16} className="text-red-600" />
                      <span>Errors</span>
                    </h3>
                    <div className="space-y-2">
                      {Array.from(errorMap.entries()).map(([assemblyName, errors]) => (
                        <div key={assemblyName} className="bg-white rounded p-3 border border-red-200">
                          <div className="font-medium text-sm text-gray-900 mb-1">{assemblyName}</div>
                          {errors.map((error, i) => (
                            <p key={i} className="text-xs text-red-600">{error}</p>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {hasWarnings && (
                  <div>
                    <h3 className="text-sm font-medium text-yellow-800 mb-2 flex items-center space-x-2">
                      <AlertTriangle size={16} className="text-yellow-600" />
                      <span>Warnings</span>
                    </h3>
                    <div className="space-y-2">
                      {Array.from(warningMap.entries()).map(([assemblyName, warnings]) => (
                        <div key={assemblyName} className="bg-white rounded p-3 border border-yellow-200">
                          <div className="font-medium text-sm text-gray-900 mb-1">{assemblyName}</div>
                          {warnings.map((warning, i) => (
                            <p key={i} className="text-xs text-yellow-600">{warning}</p>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div className="flex-none p-6 border-t bg-gray-50 flex justify-end space-x-3">
          <button
            onClick={onCancel}
            className="px-4 py-2 text-gray-700 hover:text-gray-900 transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            disabled={hasErrors}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center space-x-2"
          >
            <CheckCircle2 size={20} />
            <span>Import Assemblies</span>
          </button>
        </div>
      </div>
    </div>
  );
}