import React from 'react';
import { TrendingUp, TrendingDown } from 'lucide-react';
import type { QualityIssue } from '../../../../types/quality';

interface TrendAnalysisProps {
  issues: QualityIssue[];
  timeframe: 'day' | 'week' | 'month' | 'all' | 'custom';
}

export function TrendAnalysis({ issues, timeframe }: TrendAnalysisProps) {
  const now = new Date();
  const getPreviousPeriod = () => {
    switch (timeframe) {
      case 'day':
        const yesterday = new Date(now);
        yesterday.setDate(yesterday.getDate() - 1);
        return yesterday;
      case 'week':
        const lastWeek = new Date(now);
        lastWeek.setDate(lastWeek.getDate() - 7);
        return lastWeek;
      case 'month':
        const lastMonth = new Date(now);
        lastMonth.setMonth(lastMonth.getMonth() - 1);
        return lastMonth;
      default:
        return new Date(now.setDate(now.getDate() - 30));
    }
  };

  const previousPeriod = getPreviousPeriod();

  // Calculate current period issues
  const currentPeriodIssues = issues.filter(i => {
    const issueDate = new Date(i.createdAt);
    switch (timeframe) {
      case 'day':
        return issueDate.toDateString() === now.toDateString();
      case 'week':
        const weekStart = new Date(now);
        weekStart.setDate(weekStart.getDate() - weekStart.getDay());
        return issueDate >= weekStart;
      case 'month':
        return issueDate.getMonth() === now.getMonth() && 
               issueDate.getFullYear() === now.getFullYear();
      default:
        return true;
    }
  });

  // Calculate previous period issues
  const previousPeriodIssues = issues.filter(i => {
    const issueDate = new Date(i.createdAt);
    switch (timeframe) {
      case 'day':
        return issueDate.toDateString() === previousPeriod.toDateString();
      case 'week':
        const weekStart = new Date(previousPeriod);
        weekStart.setDate(weekStart.getDate() - weekStart.getDay());
        const weekEnd = new Date(weekStart);
        weekEnd.setDate(weekEnd.getDate() + 6);
        return issueDate >= weekStart && issueDate <= weekEnd;
      case 'month':
        return issueDate.getMonth() === previousPeriod.getMonth() && 
               issueDate.getFullYear() === previousPeriod.getFullYear();
      default:
        return false;
    }
  });

  const calculateTrend = (current: number, previous: number) => {
    if (previous === 0) return current > 0 ? 100 : 0;
    return ((current - previous) / previous) * 100;
  };

  const openedTrend = calculateTrend(
    currentPeriodIssues.length,
    previousPeriodIssues.length
  );

  const resolvedTrend = calculateTrend(
    currentPeriodIssues.filter(i => i.status === 'resolved').length,
    previousPeriodIssues.filter(i => i.status === 'resolved').length
  );

  const highSeverityTrend = calculateTrend(
    currentPeriodIssues.filter(i => i.severity === 'high').length,
    previousPeriodIssues.filter(i => i.severity === 'high').length
  );

  const avgResolutionTimeTrend = calculateTrend(
    calculateAvgResolutionTime(currentPeriodIssues),
    calculateAvgResolutionTime(previousPeriodIssues)
  );

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <h3 className="text-lg font-medium text-gray-900 mb-6">Trend Analysis</h3>
      
      <div className="space-y-6">
        {/* New Issues Trend */}
        <TrendItem
          label="New Issues"
          trend={openedTrend}
          current={currentPeriodIssues.length}
          previous={previousPeriodIssues.length}
          timeframe={timeframe}
          isPositiveBad
        />

        {/* Resolved Issues Trend */}
        <TrendItem
          label="Resolved Issues"
          trend={resolvedTrend}
          current={currentPeriodIssues.filter(i => i.status === 'resolved').length}
          previous={previousPeriodIssues.filter(i => i.status === 'resolved').length}
          timeframe={timeframe}
        />

        {/* High Severity Issues Trend */}
        <TrendItem
          label="High Severity Issues"
          trend={highSeverityTrend}
          current={currentPeriodIssues.filter(i => i.severity === 'high').length}
          previous={previousPeriodIssues.filter(i => i.severity === 'high').length}
          timeframe={timeframe}
          isPositiveBad
        />

        {/* Resolution Time Trend */}
        <TrendItem
          label="Avg Resolution Time"
          trend={avgResolutionTimeTrend}
          current={calculateAvgResolutionTime(currentPeriodIssues)}
          previous={calculateAvgResolutionTime(previousPeriodIssues)}
          timeframe={timeframe}
          isPositiveBad
          format="time"
        />
      </div>
    </div>
  );
}

interface TrendItemProps {
  label: string;
  trend: number;
  current: number;
  previous: number;
  timeframe: string;
  isPositiveBad?: boolean;
  format?: 'number' | 'time';
}

function TrendItem({ 
  label, 
  trend, 
  current, 
  previous, 
  timeframe,
  isPositiveBad = false,
  format = 'number'
}: TrendItemProps) {
  const formatValue = (value: number) => {
    if (format === 'time') {
      return value === 0 ? 'N/A' : `${Math.round(value)}h`;
    }
    return value;
  };

  const isPositive = trend > 0;
  const isGood = isPositiveBad ? !isPositive : isPositive;

  return (
    <div>
      <div className="flex items-center justify-between mb-2">
        <span className="text-sm text-gray-600">{label}</span>
        <div className="flex items-center space-x-2">
          {isPositive ? (
            <TrendingUp className={isGood ? 'text-green-500' : 'text-red-500'} size={20} />
          ) : (
            <TrendingDown className={isGood ? 'text-green-500' : 'text-red-500'} size={20} />
          )}
          <span className={`text-sm font-medium ${
            isGood ? 'text-green-500' : 'text-red-500'
          }`}>
            {Math.abs(Math.round(trend))}%
          </span>
        </div>
      </div>
      <div className="text-xs text-gray-500 flex justify-between">
        <span>Current: {formatValue(current)}</span>
        <span>Previous {timeframe}: {formatValue(previous)}</span>
      </div>
    </div>
  );
}

function calculateAvgResolutionTime(issues: QualityIssue[]): number {
  const resolvedIssues = issues.filter(i => i.status === 'resolved');
  if (resolvedIssues.length === 0) return 0;

  const totalTime = resolvedIssues.reduce((acc, issue) => {
    const created = new Date(issue.createdAt);
    const resolved = new Date(issue.updatedAt);
    return acc + (resolved.getTime() - created.getTime());
  }, 0);

  return totalTime / (resolvedIssues.length * 1000 * 60 * 60); // Convert to hours
}