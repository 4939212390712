import html2canvas from 'html2canvas';

export async function exportDashboardAsPDF(dashboardRef: HTMLElement) {
  try {
    // Create canvas from the dashboard element
    const canvas = await html2canvas(dashboardRef, {
      scale: 2, // Higher scale for better quality
      useCORS: true, // Enable cross-origin image loading
      logging: false,
      backgroundColor: '#f9fafb' // Match the dashboard background
    });

    // Convert canvas to PNG and trigger download
    const imgData = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.href = imgData;
    link.download = `quality-dashboard-${new Date().toISOString().split('T')[0]}.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    return true;
  } catch (error) {
    console.error('Error exporting dashboard:', error);
    return false;
  }
}