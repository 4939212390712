import React from 'react';
import { useDroppable } from '@dnd-kit/core';
import type { QualityIssue } from '../../../types/quality';

interface KanbanColumnProps {
  title: string;
  status: QualityIssue['status'];
  count: number;
  children: React.ReactNode;
}

export function KanbanColumn({ title, status, count, children }: KanbanColumnProps) {
  const { setNodeRef } = useDroppable({
    id: status
  });

  return (
    <div 
      ref={setNodeRef}
      className="flex flex-col h-full bg-gray-50 rounded-lg border border-gray-200"
    >
      <div className="p-4 border-b border-gray-200">
        <div className="flex items-center justify-between">
          <h3 className="font-medium text-gray-900">{title}</h3>
          <span className="text-sm text-gray-500">{count}</span>
        </div>
      </div>
      <div className="flex-1 p-4 space-y-4 overflow-y-auto">
        {children}
      </div>
    </div>
  );
}