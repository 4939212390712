import { QualityIssue } from '../../types/quality';

export function filterIssuesByTimeframe(
  issues: QualityIssue[],
  timeframe: 'day' | 'week' | 'month' | 'all' | 'custom',
  dateRange: { start: Date; end: Date } | null = null
): QualityIssue[] {
  const now = new Date();

  return issues.filter(issue => {
    const issueDate = new Date(issue.createdAt);

    switch (timeframe) {
      case 'day':
        // Show only today's issues
        return issueDate.toDateString() === now.toDateString();

      case 'week':
        // Show current week (Monday-Sunday)
        const weekStart = new Date(now);
        weekStart.setDate(weekStart.getDate() - weekStart.getDay() + (weekStart.getDay() === 0 ? -6 : 1));
        weekStart.setHours(0, 0, 0, 0);
        const weekEnd = new Date(weekStart);
        weekEnd.setDate(weekEnd.getDate() + 6);
        weekEnd.setHours(23, 59, 59, 999);
        return issueDate >= weekStart && issueDate <= weekEnd;

      case 'month':
        // Show current calendar month
        return issueDate.getMonth() === now.getMonth() && 
               issueDate.getFullYear() === now.getFullYear();

      case 'custom':
        // Show issues within custom date range
        if (!dateRange) return true;
        const start = new Date(dateRange.start);
        start.setHours(0, 0, 0, 0);
        const end = new Date(dateRange.end);
        end.setHours(23, 59, 59, 999);
        return issueDate >= start && issueDate <= end;

      case 'all':
      default:
        // Show all issues
        return true;
    }
  });
}

export function groupIssuesByTime(
  issues: QualityIssue[], 
  timeframe: 'day' | 'week' | 'month' | 'all' | 'custom',
  dateRange: { start: Date; end: Date } | null = null
): Record<string, number> {
  const grouped: Record<string, number> = {};
  const now = new Date();

  // Initialize periods based on timeframe
  if (timeframe === 'day') {
    // Show last 7 days
    for (let i = 6; i >= 0; i--) {
      const date = new Date(now);
      date.setDate(date.getDate() - i);
      grouped[formatDate(date)] = 0;
    }
  } else if (timeframe === 'week') {
    // Show last 6 weeks
    for (let i = 5; i >= 0; i--) {
      const date = new Date(now);
      date.setDate(date.getDate() - (i * 7));
      const weekStart = new Date(date);
      weekStart.setDate(weekStart.getDate() - weekStart.getDay() + (weekStart.getDay() === 0 ? -6 : 1));
      grouped[formatWeek(weekStart)] = 0;
    }
  } else if (timeframe === 'month') {
    // Show last 6 months
    for (let i = 5; i >= 0; i--) {
      const date = new Date(now);
      date.setMonth(date.getMonth() - i);
      grouped[formatMonth(date)] = 0;
    }
  } else if (timeframe === 'custom' && dateRange) {
    // For custom range, create periods between start and end dates
    const days = Math.ceil((dateRange.end.getTime() - dateRange.start.getTime()) / (1000 * 60 * 60 * 24));
    for (let i = 0; i <= days; i++) {
      const date = new Date(dateRange.start);
      date.setDate(date.getDate() + i);
      grouped[formatDate(date)] = 0;
    }
  }

  // Count issues
  issues.forEach(issue => {
    const date = new Date(issue.createdAt);
    let key: string;

    if (timeframe === 'day') {
      key = formatDate(date);
    } else if (timeframe === 'week') {
      const weekStart = new Date(date);
      weekStart.setDate(weekStart.getDate() - weekStart.getDay() + (weekStart.getDay() === 0 ? -6 : 1));
      key = formatWeek(weekStart);
    } else if (timeframe === 'month') {
      key = formatMonth(date);
    } else if (timeframe === 'custom') {
      key = formatDate(date);
    } else {
      // All time view - group by month
      key = formatMonth(date);
    }

    if (key in grouped) {
      grouped[key]++;
    }
  });

  return grouped;
}

function formatDate(date: Date): string {
  return date.toLocaleDateString('en-US', { 
    weekday: 'short',
    month: 'short', 
    day: 'numeric'
  });
}

function formatWeek(date: Date): string {
  const start = new Date(date);
  const end = new Date(start);
  end.setDate(end.getDate() + 6);

  return `${formatDate(start)} - ${formatDate(end)}`;
}

function formatMonth(date: Date): string {
  return date.toLocaleDateString('en-US', { 
    month: 'long',
    year: 'numeric'
  });
}