import { useState, useEffect } from 'react';
import { 
  collection,
  doc,
  setDoc,
  deleteDoc,
  onSnapshot,
  query,
  orderBy,
  updateDoc,
  serverTimestamp
} from 'firebase/firestore';
import { db } from '../config/firebase';
import { useAuth } from '../contexts/AuthContext';
import { handleOfflineState } from '../utils/offline';
import { convertTimestamp } from '../utils/firestore';
import { DEFAULT_RESOLUTION_STEPS } from '../utils/quality/resolutionSteps';
import type { QualityIssue } from '../types/quality';

export function useQualityIssues() {
  const { currentUser } = useAuth();
  const [issues, setIssues] = useState<QualityIssue[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isOffline, setIsOffline] = useState(false);

  useEffect(() => {
    if (!currentUser) {
      setIssues([]);
      setIsLoading(false);
      return;
    }

    const userRef = doc(db, 'users', currentUser.uid);
    const issuesRef = collection(userRef, 'quality-issues');
    const issuesQuery = query(issuesRef, orderBy('createdAt', 'desc'));

    const unsubscribe = onSnapshot(
      issuesQuery,
      (snapshot) => {
        const issueData: QualityIssue[] = [];
        snapshot.forEach((doc) => {
          const data = doc.data();
          issueData.push({
            ...data,
            id: doc.id,
            createdAt: convertTimestamp(data.createdAt),
            updatedAt: convertTimestamp(data.updatedAt),
            resolutionSteps: (data.resolutionSteps || DEFAULT_RESOLUTION_STEPS).map((step: any) => ({
              ...step,
              actionSteps: Array.isArray(step.actionSteps) ? step.actionSteps : []
            }))
          } as QualityIssue);
        });
        setIssues(issueData);
        setIsLoading(false);
        setError(null);
        setIsOffline(false);
      },
      (err) => {
        console.error('Firestore subscription error:', err);
        const isOfflineErr = handleOfflineState(err);
        setIsOffline(isOfflineErr);
        if (!isOfflineErr) {
          setError('Error loading quality issues. Please try again.');
        }
        setIsLoading(false);
      }
    );

    return () => unsubscribe();
  }, [currentUser]);

  const saveIssue = async (issue: Omit<QualityIssue, 'id' | 'createdAt' | 'updatedAt'> & { id?: string }) => {
    if (!currentUser) {
      setError('You must be logged in to save issues');
      return;
    }

    try {
      const userRef = doc(db, 'users', currentUser.uid);
      const issueId = issue.id || crypto.randomUUID();
      const issueRef = doc(collection(userRef, 'quality-issues'), issueId);

      // Ensure resolutionSteps and actionSteps are properly structured
      const formattedIssue = {
        ...issue,
        resolutionSteps: (issue.resolutionSteps || DEFAULT_RESOLUTION_STEPS).map(step => ({
          ...step,
          actionSteps: Array.isArray(step.actionSteps) ? step.actionSteps : []
        })),
        updatedAt: serverTimestamp()
      };

      if (issue.id) {
        // Update existing issue
        await updateDoc(issueRef, formattedIssue);
      } else {
        // Create new issue
        await setDoc(issueRef, {
          ...formattedIssue,
          id: issueId,
          createdAt: serverTimestamp(),
        });
      }

      setError(null);
      return issueId;
    } catch (err) {
      console.error('Error saving issue:', err);
      setError('Failed to save issue. Please try again.');
      throw err;
    }
  };

  const updateIssueStatus = async (issueId: string, newStatus: QualityIssue['status']) => {
    if (!currentUser) {
      setError('You must be logged in to update issues');
      return;
    }

    try {
      const userRef = doc(db, 'users', currentUser.uid);
      const issueRef = doc(collection(userRef, 'quality-issues'), issueId);

      await updateDoc(issueRef, {
        status: newStatus,
        updatedAt: serverTimestamp(),
      });

      setError(null);
    } catch (err) {
      console.error('Error updating issue status:', err);
      setError('Failed to update issue status. Please try again.');
      throw err;
    }
  };

  const deleteIssue = async (issueId: string) => {
    if (!currentUser) {
      setError('You must be logged in to delete issues');
      return;
    }

    try {
      const userRef = doc(db, 'users', currentUser.uid);
      const issueRef = doc(collection(userRef, 'quality-issues'), issueId);
      await deleteDoc(issueRef);
      setError(null);
    } catch (err) {
      console.error('Error deleting issue:', err);
      setError('Failed to delete issue. Please try again.');
      throw err;
    }
  };

  return {
    issues,
    isLoading,
    error,
    isOffline,
    saveIssue,
    updateIssueStatus,
    deleteIssue,
  };
}