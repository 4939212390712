import React, { useState } from 'react';
import { X } from 'lucide-react';
import { CategorySelect } from './CategorySelect';
import { DEFAULT_RESOLUTION_STEPS } from '../../../utils/quality/resolutionSteps';
import type { QualityIssue, QualityIssueCategory } from '../../../types/quality';

interface QualityIssueFormProps {
  issue?: QualityIssue | null;
  onSubmit: (formData: Omit<QualityIssue, 'id' | 'createdAt' | 'updatedAt'>) => void;
  onCancel: () => void;
}

export function QualityIssueForm({ issue, onSubmit, onCancel }: QualityIssueFormProps) {
  const [formData, setFormData] = useState(() => ({
    issueNumber: issue?.issueNumber || `QI-${Date.now()}`,
    partNumber: issue?.partNumber || '',
    serialNumber: issue?.serialNumber || '',
    title: issue?.title || '',
    description: issue?.description || '',
    severity: issue?.severity || 'medium',
    status: issue?.status || 'open',
    assignedTo: issue?.assignedTo || '',
    category: issue?.category || null,
    resolutionSteps: issue?.resolutionSteps || [...DEFAULT_RESOLUTION_STEPS]
  }));

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <>
      {/* Fixed Header */}
      <div className="flex-none px-6 py-4 border-b border-gray-200 bg-white">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-bold text-gray-900">
            {issue ? 'Edit Issue' : 'Create New Issue'}
          </h2>
          <button
            onClick={onCancel}
            className="p-2 text-gray-400 hover:text-gray-500 transition-colors"
          >
            <X size={20} />
          </button>
        </div>
      </div>

      {/* Scrollable Content */}
      <div className="flex-1 overflow-y-auto p-6">
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-3 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Issue Number
              </label>
              <input
                type="text"
                value={formData.issueNumber}
                onChange={(e) => setFormData({ ...formData, issueNumber: e.target.value })}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Part Number
              </label>
              <input
                type="text"
                value={formData.partNumber}
                onChange={(e) => setFormData({ ...formData, partNumber: e.target.value })}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Serial Number
              </label>
              <input
                type="text"
                value={formData.serialNumber}
                onChange={(e) => setFormData({ ...formData, serialNumber: e.target.value })}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                required
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Title
            </label>
            <input
              type="text"
              value={formData.title}
              onChange={(e) => setFormData({ ...formData, title: e.target.value })}
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Description
            </label>
            <textarea
              value={formData.description}
              onChange={(e) => setFormData({ ...formData, description: e.target.value })}
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              rows={5}
              required
            />
          </div>

          <div className="grid grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Severity
              </label>
              <select
                value={formData.severity}
                onChange={(e) => setFormData({ ...formData, severity: e.target.value as QualityIssue['severity'] })}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
              >
                <option value="low">Low</option>
                <option value="medium">Medium</option>
                <option value="high">High</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Status
              </label>
              <select
                value={formData.status}
                onChange={(e) => setFormData({ ...formData, status: e.target.value as QualityIssue['status'] })}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
              >
                <option value="open">Open</option>
                <option value="in-progress">In Progress</option>
                <option value="resolved">Resolved</option>
              </select>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Issue Category
            </label>
            <CategorySelect
              value={formData.category}
              onChange={(category) => setFormData(prev => ({ ...prev, category }))}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Assign To (Optional)
            </label>
            <input
              type="text"
              value={formData.assignedTo}
              onChange={(e) => setFormData({ ...formData, assignedTo: e.target.value })}
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              placeholder="Enter assignee name"
            />
          </div>
        </form>
      </div>

      {/* Fixed Footer */}
      <div className="flex-none px-6 py-4 border-t border-gray-200 bg-white">
        <div className="flex justify-end space-x-3">
          <button
            type="button"
            onClick={onCancel}
            className="px-4 py-2 text-gray-700 hover:text-gray-900 transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={(e) => handleSubmit(e)}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            {issue ? 'Update Issue' : 'Create Issue'}
          </button>
        </div>
      </div>
    </>
  );
}