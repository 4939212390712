import Papa from 'papaparse';
import type { AssemblyCSVData } from '../../types/csv';

export async function parseAssemblyCSV(file: File): Promise<AssemblyCSVData[]> {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: 'greedy',
      transformHeader: (header) => {
        const normalized = header.trim().toLowerCase().replace(/\s+/g, '');
        switch (normalized) {
          case 'assemblyname':
            return 'assemblyName';
          case 'childassemblies':
            return 'childAssemblies';
          case 'procedures':
            return 'procedures';
          default:
            return normalized;
        }
      },
      transform: (value) => value.trim(),
      complete: (results) => {
        if (results.errors.length > 0) {
          reject(new Error(`CSV parsing error: ${results.errors[0].message}`));
          return;
        }

        try {
          const assemblies: AssemblyCSVData[] = results.data
            .filter((row: any) => row.assemblyName)
            .map((row: any) => ({
              assemblyName: row.assemblyName.trim(),
              childAssemblies: row.childAssemblies
                ? row.childAssemblies
                    .split(';')
                    .map((s: string) => s.trim())
                    .filter(Boolean)
                : [],
              procedures: row.procedures
                ? row.procedures
                    .split(';')
                    .map((s: string) => s.trim())
                    .filter(Boolean)
                : [],
            }));

          resolve(assemblies);
        } catch (err) {
          reject(new Error('Failed to process CSV data: ' + (err instanceof Error ? err.message : 'Unknown error')));
        }
      },
      error: (error) => {
        reject(new Error('Failed to parse CSV: ' + error.message));
      },
    });
  });
}