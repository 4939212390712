export const QUALITY_CATEGORIES = [
 {
    id: 'material-defects',
    name: 'Material Defects',
    subcategories: [
      {
        id: 'raw-material-issues',
        name: 'Raw Material Issues',
        subcategories: [
          { id: 'impurities', name: 'Impurities in Materials' },
          { id: 'incorrect-grade', name: 'Incorrect Material Grade' },
          { id: 'composition-variations', name: 'Material Composition Variations' }
        ]
      },
      {
        id: 'material-handling',
        name: 'Material Handling',
        subcategories: [
          { id: 'improper-storage', name: 'Improper Storage' },
          { id: 'contamination', name: 'Contamination During Handling' },
          { id: 'damage-transport', name: 'Damage During Transportation' }
        ]
      }
    ]
  },
  {
    id: 'process-defects',
    name: 'Process Defects',
    subcategories: [
      {
        id: 'machining-defects',
        name: 'Machining Defects',
        subcategories: [
          { id: 'dimensional-inaccuracy', name: 'Dimensional Inaccuracy' },
          { id: 'surface-roughness', name: 'Surface Roughness' },
          { id: 'burrs', name: 'Burrs or Sharp Edges' }
        ]
      },
      {
        id: 'assembly-issues',
        name: 'Assembly Issues',
        subcategories: [
          { id: 'misaligned-components', name: 'Misaligned Components' },
          { id: 'incorrect-torque', name: 'Incorrect Fastener Torque' },
          { id: 'missing-components', name: 'Missing or Extra Components' }
        ]
      },
      {
        id: 'welding-defects',
        name: 'Welding or Joining Defects',
        subcategories: [
          { id: 'poor-penetration', name: 'Poor Weld Penetration' },
          { id: 'cracks-porosity', name: 'Cracks or Porosity in Welds' },
          { id: 'adhesive-failures', name: 'Adhesive Bonding Failures' }
        ]
      },
      {
        id: 'coating-defects',
        name: 'Coating and Painting Defects',
        subcategories: [
          { id: 'uneven-coating', name: 'Uneven Coating Thickness' },
          { id: 'bubbles-peeling', name: 'Bubbles or Peeling' },
          { id: 'incorrect-curing', name: 'Incorrect Curing or Drying' }
        ]
      }
    ]
  },
  {
    id: 'design-defects',
    name: 'Design Defects',
    subcategories: [
      {
        id: 'tolerance-issues',
        name: 'Tolerance Stack-Up Issues',
        subcategories: [
          { id: 'interference', name: 'Interference Between Parts' },
          { id: 'loose-fit', name: 'Loose Fit or Excessive Gaps' }
        ]
      },
      {
        id: 'complex-designs',
        name: 'Overcomplicated Designs',
        subcategories: [
          { id: 'difficult-features', name: 'Difficult to Manufacture Features' },
          { id: 'misunderstood-capabilities', name: 'Misunderstanding of Manufacturing Capabilities' }
        ]
      },
      {
        id: 'lack-testing',
        name: 'Lack of Testing or Validation',
        subcategories: [
          { id: 'stress-failures', name: 'Unforeseen Stress Failures' },
          { id: 'insufficient-prototyping', name: 'Insufficient Prototyping' }
        ]
      }
    ]
  },
  {
    id: 'human-errors',
    name: 'Human Errors',
    subcategories: [
      {
        id: 'operator-errors',
        name: 'Operator Errors',
        subcategories: [
          { id: 'misinterpret-instructions', name: 'Misinterpretation of Instructions' },
          { id: 'incorrect-tool', name: 'Incorrect Tool Usage' },
          { id: 'skipped-steps', name: 'Skipping Process Steps' }
        ]
      },
      {
        id: 'inspection-errors',
        name: 'Inspection and Quality Control Errors',
        subcategories: [
          { id: 'missed-defects', name: 'Missed Defects During Inspection' },
          { id: 'measurement-errors', name: 'Incorrect Measurement Techniques' },
          { id: 'documentation-errors', name: 'Documentation Errors' }
        ]
      }
    ]
  },
  {
    id: 'environmental-defects',
    name: 'Environmental Defects',
    subcategories: [
      {
        id: 'contamination-production',
        name: 'Contamination During Production',
        subcategories: [
          { id: 'dust-particulate', name: 'Dust or Particulate Matter' },
          { id: 'oil-grease', name: 'Oil or Grease Contamination' }
        ]
      },
      {
        id: 'uncontrolled-environment',
        name: 'Uncontrolled Environmental Conditions',
        subcategories: [
          { id: 'temperature-issues', name: 'High or Low Temperatures' },
          { id: 'humidity', name: 'Humidity Levels Outside Tolerance' },
          { id: 'vibration', name: 'Vibration During Processing' }
        ]
      }
    ]
  },
  {
    id: 'tooling-equipment-issues',
    name: 'Tooling and Equipment Issues',
    subcategories: [
      {
        id: 'tool-wear',
        name: 'Tool Wear or Damage',
        subcategories: [
          { id: 'dull-tools', name: 'Dull Cutting Tools' },
          { id: 'miscalibrated-tools', name: 'Miscalibrated Measuring Devices' }
        ]
      },
      {
        id: 'equipment-malfunction',
        name: 'Equipment Malfunction',
        subcategories: [
          { id: 'misaligned-machinery', name: 'Misaligned Machinery' },
          { id: 'breakdown', name: 'Sudden Breakdown or Failure' }
        ]
      },
      {
        id: 'setup-errors',
        name: 'Incorrect Tooling Setup',
        subcategories: [
          { id: 'fixture-placement', name: 'Incorrect Fixture Placement' },
          { id: 'machine-parameters', name: 'Misconfigured Machine Parameters' }
        ]
      }
    ]
  },
  {
    id: 'supply-chain-defects',
    name: 'Supply Chain Defects',
    subcategories: [
      {
        id: 'delayed-deliveries',
        name: 'Delayed Deliveries',
        subcategories: [
          { id: 'timeline-impact', name: 'Impact on Production Timelines' },
          { id: 'rushed-checks', name: 'Rushed or Incomplete Quality Checks' }
        ]
      },
      {
        id: 'substandard-parts',
        name: 'Substandard Vendor Parts',
        subcategories: [
          { id: 'counterfeit-materials', name: 'Counterfeit Materials' },
          { id: 'low-quality', name: 'Subpar Quality Standards' }
        ]
      },
      {
        id: 'documentation-errors',
        name: 'Documentation or Communication Errors',
        subcategories: [
          { id: 'incorrect-specs', name: 'Incorrect Specifications Provided' },
          { id: 'traceability-issues', name: 'Lack of Traceability' }
        ]
      }
    ]
  }
] as const;

export function findCategory(categoryId: string, subcategoryId?: string, subsubcategoryId?: string) {
  const category = QUALITY_CATEGORIES.find(c => c.id === categoryId);
  if (!category) return null;

  if (!subcategoryId) return category;

  const subcategory = category.subcategories?.find(s => s.id === subcategoryId);
  if (!subcategory) return null;

  if (!subsubcategoryId) return subcategory;

  return subcategory.subcategories?.find(s => s.id === subsubcategoryId) || null;
}

export function getCategoryPath(categoryId: string, subcategoryId?: string, subsubcategoryId?: string): string {
  const parts: string[] = [];
  
  const category = QUALITY_CATEGORIES.find(c => c.id === categoryId);
  if (category) {
    parts.push(category.name);
    
    if (subcategoryId) {
      const subcategory = category.subcategories?.find(s => s.id === subcategoryId);
      if (subcategory) {
        parts.push(subcategory.name);
        
        if (subsubcategoryId) {
          const subsubcategory = subcategory.subcategories?.find(s => s.id === subsubcategoryId);
          if (subsubcategory) {
            parts.push(subsubcategory.name);
          }
        }
      }
    }
  }
  
  return parts.join(' > ');
}