import { QualityIssue } from '../../types/quality';

interface TimeMetrics {
  averageTimeToClose: string;
  averageTimeOpen: string;
  longestOpenIssue: string;
  quickestResolution: string;
}

export function calculateTimeMetrics(issues: QualityIssue[]): TimeMetrics {
  const now = new Date();
  const DAY = 1000 * 60 * 60 * 24;

  // Calculate time to close for resolved issues
  const resolvedIssues = issues.filter(i => i.status === 'resolved');
  const timeToClose = resolvedIssues.map(issue => {
    const created = new Date(issue.createdAt);
    const resolved = new Date(issue.updatedAt);
    return Math.ceil((resolved.getTime() - created.getTime()) / DAY);
  });

  // Calculate time open for unresolved issues
  const unresolvedIssues = issues.filter(i => i.status !== 'resolved');
  const timeOpen = unresolvedIssues.map(issue => {
    const created = new Date(issue.createdAt);
    return Math.ceil((now.getTime() - created.getTime()) / DAY);
  });

  // Calculate metrics
  const avgTimeToClose = timeToClose.length > 0
    ? Math.round(timeToClose.reduce((a, b) => a + b, 0) / timeToClose.length)
    : 0;

  const avgTimeOpen = timeOpen.length > 0
    ? Math.round(timeOpen.reduce((a, b) => a + b, 0) / timeOpen.length)
    : 0;

  const longest = Math.max(...timeOpen, 0);
  const quickest = Math.min(...timeToClose, Number.MAX_SAFE_INTEGER);

  return {
    averageTimeToClose: formatDuration(avgTimeToClose),
    averageTimeOpen: formatDuration(avgTimeOpen),
    longestOpenIssue: formatDuration(longest),
    quickestResolution: formatDuration(quickest === Number.MAX_SAFE_INTEGER ? 0 : quickest)
  };
}

function formatDuration(days: number): string {
  if (days === 0) return 'N/A';
  if (days < 1) return '< 1 day';
  if (days === 1) return '1 day';
  if (days < 30) return `${days} days`;
  const months = Math.round(days / 30);
  return months === 1 ? '1 month' : `${months} months`;
}