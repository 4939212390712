import React from 'react';
import { Home, Rocket, Cable, BarChart2, Settings, FileSpreadsheet } from 'lucide-react';

interface SidebarProps {
  activeSection: 'dashboard' | 'satellites' | 'procedures' | 'harnesses' | 'quality' | 'settings';
  onSectionChange: (section: 'dashboard' | 'satellites' | 'procedures' | 'harnesses' | 'quality' | 'settings') => void;
}

export function Sidebar({ activeSection, onSectionChange }: SidebarProps) {
  return (
    <div className="w-20 bg-gradient-to-b from-indigo-900 to-indigo-800 shadow-lg flex flex-col items-center py-4">
      <div className="space-y-6 flex flex-col items-center w-full">
        <button
          onClick={() => onSectionChange('settings')}
          className={`w-16 flex flex-col items-center p-2 rounded-lg transition-colors ${
            activeSection === 'settings'
              ? 'bg-indigo-700 text-white'
              : 'text-indigo-300 hover:text-white hover:bg-indigo-700/50'
          }`}
          title="Settings"
        >
          <Settings size={24} />
          <span className="text-[10px] mt-1">Settings</span>
        </button>

        <button
          onClick={() => onSectionChange('dashboard')}
          className={`w-16 flex flex-col items-center p-2 rounded-lg transition-colors ${
            activeSection === 'dashboard'
              ? 'bg-indigo-700 text-white'
              : 'text-indigo-300 hover:text-white hover:bg-indigo-700/50'
          }`}
          title="Dashboard"
        >
          <Home size={24} />
          <span className="text-[10px] mt-1">Home</span>
        </button>

        <button
          onClick={() => onSectionChange('satellites')}
          className={`w-16 flex flex-col items-center p-2 rounded-lg transition-colors ${
            activeSection === 'satellites'
              ? 'bg-indigo-700 text-white'
              : 'text-indigo-300 hover:text-white hover:bg-indigo-700/50'
          }`}
          title="Assemblies"
        >
          <Rocket size={24} />
          <span className="text-[10px] mt-1">Assemblies</span>
        </button>

        <button
          onClick={() => onSectionChange('procedures')}
          className={`w-16 flex flex-col items-center p-2 rounded-lg transition-colors ${
            activeSection === 'procedures'
              ? 'bg-indigo-700 text-white'
              : 'text-indigo-300 hover:text-white hover:bg-indigo-700/50'
          }`}
          title="Procedures"
        >
          <FileSpreadsheet size={24} />
          <span className="text-[10px] mt-1">Procedures</span>
        </button>

        <button
          onClick={() => onSectionChange('harnesses')}
          className={`w-16 flex flex-col items-center p-2 rounded-lg transition-colors ${
            activeSection === 'harnesses'
              ? 'bg-indigo-700 text-white'
              : 'text-indigo-300 hover:text-white hover:bg-indigo-700/50'
          }`}
          title="Harnesses"
        >
          <Cable size={24} />
          <span className="text-[10px] mt-1">Harnesses</span>
        </button>

        <button
          onClick={() => onSectionChange('quality')}
          className={`w-16 flex flex-col items-center p-2 rounded-lg transition-colors ${
            activeSection === 'quality'
              ? 'bg-indigo-700 text-white'
              : 'text-indigo-300 hover:text-white hover:bg-indigo-700/50'
          }`}
          title="Quality"
        >
          <BarChart2 size={24} />
          <span className="text-[10px] mt-1">Quality</span>
        </button>
      </div>
    </div>
  );
}