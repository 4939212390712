import React from 'react';
import { BarChart2, AlertTriangle, CheckCircle2, Clock } from 'lucide-react';
import { StatCard } from './StatCard';
import { StatItem } from './StatItem';
import type { QualityIssue } from '../../../types/quality';

interface QualityMetricsProps {
  issues: QualityIssue[];
}

export function QualityMetrics({ issues }: QualityMetricsProps) {
  const totalIssues = issues.length;
  const openIssues = issues.filter(i => i.status === 'open').length;
  const inProgressIssues = issues.filter(i => i.status === 'in-progress').length;
  const resolvedIssues = issues.filter(i => i.status === 'resolved').length;
  
  const highSeverity = issues.filter(i => i.severity === 'high' && i.status !== 'resolved').length;
  const mediumSeverity = issues.filter(i => i.severity === 'medium' && i.status !== 'resolved').length;
  const lowSeverity = issues.filter(i => i.severity === 'low' && i.status !== 'resolved').length;

  const qualityScore = totalIssues > 0 
    ? Math.round((resolvedIssues / totalIssues) * 100)
    : 100;

  return (
    <StatCard title="Quality Overview" icon={<BarChart2 className="text-indigo-600" size={24} />}>
      <div className="space-y-6">
        {/* Status Overview */}
        <div className="space-y-2">
          <h4 className="text-sm font-medium text-gray-700">Status</h4>
          <div className="grid gap-2">
            <StatItem
              icon={<AlertTriangle className="text-red-500" size={20} />}
              label="Open Issues"
              value={openIssues}
            />
            <StatItem
              icon={<Clock className="text-blue-500" size={20} />}
              label="In Progress"
              value={inProgressIssues}
            />
            <StatItem
              icon={<CheckCircle2 className="text-green-500" size={20} />}
              label="Resolved"
              value={resolvedIssues}
            />
          </div>
        </div>

        {/* Severity Breakdown */}
        <div className="space-y-2">
          <h4 className="text-sm font-medium text-gray-700">Active Issues by Severity</h4>
          <div className="grid gap-2">
            <div className="flex justify-between items-center">
              <div className="flex items-center space-x-2">
                <span className="w-2 h-2 rounded-full bg-red-500"></span>
                <span className="text-sm text-gray-600">High</span>
              </div>
              <span className="font-medium">{highSeverity}</span>
            </div>
            <div className="flex justify-between items-center">
              <div className="flex items-center space-x-2">
                <span className="w-2 h-2 rounded-full bg-yellow-500"></span>
                <span className="text-sm text-gray-600">Medium</span>
              </div>
              <span className="font-medium">{mediumSeverity}</span>
            </div>
            <div className="flex justify-between items-center">
              <div className="flex items-center space-x-2">
                <span className="w-2 h-2 rounded-full bg-blue-500"></span>
                <span className="text-sm text-gray-600">Low</span>
              </div>
              <span className="font-medium">{lowSeverity}</span>
            </div>
          </div>
        </div>

        {/* Quality Score */}
        <div className="space-y-2">
          <div className="flex justify-between items-center">
            <span className="text-sm font-medium text-gray-700">Quality Score</span>
            <span className={`text-lg font-bold ${
              qualityScore >= 90 ? 'text-green-500' :
              qualityScore >= 70 ? 'text-yellow-500' :
              'text-red-500'
            }`}>
              {qualityScore}%
            </span>
          </div>
          <div className="w-full bg-gray-200 rounded-full h-2">
            <div
              className={`h-2 rounded-full transition-all duration-300 ${
                qualityScore >= 90 ? 'bg-green-500' :
                qualityScore >= 70 ? 'bg-yellow-500' :
                'bg-red-500'
              }`}
              style={{ width: `${qualityScore}%` }}
            />
          </div>
        </div>
      </div>
    </StatCard>
  );
}