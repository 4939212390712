import React, { useState } from 'react';
import { Calendar, ChevronDown } from 'lucide-react';
import { DateRangePicker } from './DateRangePicker';

interface TimelineFilterProps {
  timeframe: 'day' | 'week' | 'month' | 'all' | 'custom';
  onTimeframeChange: (timeframe: 'day' | 'week' | 'month' | 'all' | 'custom') => void;
  dateRange?: { start: Date; end: Date } | null;
  onDateRangeChange?: (range: { start: Date; end: Date } | null) => void;
}

export function TimelineFilter({ 
  timeframe, 
  onTimeframeChange,
  dateRange,
  onDateRangeChange 
}: TimelineFilterProps) {
  const [showDatePicker, setShowDatePicker] = useState(false);

  return (
    <div className="flex items-center space-x-2 bg-white rounded-lg shadow p-2">
      <Calendar className="text-gray-400" size={20} />
      <div className="flex rounded-lg border border-gray-200 divide-x">
        {(['day', 'week', 'month', 'all', 'custom'] as const).map(period => (
          <button
            key={period}
            onClick={() => {
              onTimeframeChange(period);
              if (period === 'custom') {
                setShowDatePicker(true);
              }
            }}
            className={`px-4 py-2 text-sm flex items-center space-x-1 ${
              timeframe === period
                ? 'bg-blue-50 text-blue-600'
                : 'text-gray-600 hover:text-gray-900'
            } transition-colors`}
          >
            <span>{period.charAt(0).toUpperCase() + period.slice(1)}</span>
            {period === 'custom' && <ChevronDown size={16} />}
          </button>
        ))}
      </div>

      {showDatePicker && (
        <DateRangePicker
          initialRange={dateRange}
          onSelect={(range) => {
            if (onDateRangeChange) {
              onDateRangeChange(range);
            }
            setShowDatePicker(false);
          }}
          onCancel={() => {
            setShowDatePicker(false);
            if (timeframe === 'custom' && !dateRange) {
              onTimeframeChange('week');
            }
          }}
        />
      )}
    </div>
  );
}