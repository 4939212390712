import React, { useState } from 'react';
import { ResolutionStepActions } from './ResolutionStepActions';
import { SortHeader } from './SortHeader';
import { DEFAULT_RESOLUTION_STEPS } from '../../../utils/quality/resolutionSteps';
import type { QualityIssue } from '../../../types/quality';

interface ActionListViewProps {
  issues: QualityIssue[];
  onUpdateIssue?: (issue: QualityIssue) => void;
}

export function ActionListView({ issues, onUpdateIssue }: ActionListViewProps) {
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc' | null>(null);

  // Filter out resolved issues and those without actions
  const issuesWithActions = issues.filter(issue =>
    issue.status !== 'resolved' && 
    issue.resolutionSteps.some(step => step.actionSteps?.length > 0)
  );

  // Sort issues if direction is set
  const sortedIssues = [...issuesWithActions].sort((a, b) => {
    if (!sortDirection) return 0;
    
    const aNum = parseInt(a.issueNumber.replace(/\D/g, ''));
    const bNum = parseInt(b.issueNumber.replace(/\D/g, ''));
    
    return sortDirection === 'asc' 
      ? aNum - bNum 
      : bNum - aNum;
  });

  const handleSort = () => {
    setSortDirection(current => {
      if (!current) return 'asc';
      if (current === 'asc') return 'desc';
      return null;
    });
  };

  // Group steps into pairs
  const stepPairs = [
    [DEFAULT_RESOLUTION_STEPS[0], DEFAULT_RESOLUTION_STEPS[1]],
    [DEFAULT_RESOLUTION_STEPS[2], DEFAULT_RESOLUTION_STEPS[3]],
    [DEFAULT_RESOLUTION_STEPS[4], DEFAULT_RESOLUTION_STEPS[5]]
  ];

  return (
    <div className="w-full h-full bg-white rounded-lg shadow-lg overflow-hidden flex flex-col">
      <div className="flex-none border-b border-gray-200">
        <SortHeader 
          onSort={handleSort}
          direction={sortDirection}
        />
      </div>
      <div className="flex-1 overflow-auto">
        {sortedIssues.length > 0 ? (
          <div className="divide-y divide-gray-200">
            {stepPairs.map((pair, index) => (
              <div key={index} className="grid grid-cols-2 gap-6 p-6">
                {pair.map(stepTemplate => (
                  <ResolutionStepActions
                    key={stepTemplate.step}
                    stepNumber={stepTemplate.step}
                    stepTitle={stepTemplate.title}
                    issues={sortedIssues}
                    onUpdateIssue={onUpdateIssue}
                  />
                ))}
              </div>
            ))}
          </div>
        ) : (
          <div className="flex items-center justify-center h-full text-gray-500">
            No active action steps found
          </div>
        )}
      </div>
    </div>
  );
}