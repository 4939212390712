import React, { useState, useEffect } from 'react';
import { ChevronRight } from 'lucide-react';
import { QUALITY_CATEGORIES } from '../../../utils/quality/categories';
import type { QualityIssueCategory } from '../../../types/quality';

interface CategorySelectProps {
  value: QualityIssueCategory | null;
  onChange: (category: QualityIssueCategory | null) => void;
}

export function CategorySelect({ value, onChange }: CategorySelectProps) {
  const [selectedCategory, setSelectedCategory] = useState(value?.categoryId || '');
  const [selectedSubcategory, setSelectedSubcategory] = useState(value?.subcategoryId || '');
  const [selectedSubsubcategory, setSelectedSubsubcategory] = useState(value?.subsubcategoryId || '');

  useEffect(() => {
    if (value) {
      setSelectedCategory(value.categoryId);
      setSelectedSubcategory(value.subcategoryId || '');
      setSelectedSubsubcategory(value.subsubcategoryId || '');
    }
  }, [value]);

  const handleCategoryClick = (e: React.MouseEvent, categoryId: string) => {
    e.preventDefault(); // Prevent form submission
    setSelectedCategory(categoryId);
    setSelectedSubcategory('');
    setSelectedSubsubcategory('');
    onChange({ categoryId });
  };

  const handleSubcategoryClick = (e: React.MouseEvent, subcategoryId: string) => {
    e.preventDefault(); // Prevent form submission
    setSelectedSubcategory(subcategoryId);
    setSelectedSubsubcategory('');
    onChange({ categoryId: selectedCategory, subcategoryId });
  };

  const handleSubsubcategoryClick = (e: React.MouseEvent, subsubcategoryId: string) => {
    e.preventDefault(); // Prevent form submission
    setSelectedSubsubcategory(subsubcategoryId);
    onChange({ 
      categoryId: selectedCategory, 
      subcategoryId: selectedSubcategory,
      subsubcategoryId 
    });
  };

  const category = QUALITY_CATEGORIES.find(c => c.id === selectedCategory);
  const subcategory = category?.subcategories?.find(s => s.id === selectedSubcategory);

  return (
    <div className="border border-gray-200 rounded-lg divide-y">
      {/* Main Categories */}
      <div className="p-3">
        <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
          {QUALITY_CATEGORIES.map(cat => (
            <button
              key={cat.id}
              type="button" // Explicitly set button type to prevent form submission
              onClick={(e) => handleCategoryClick(e, cat.id)}
              className={`
                text-left p-3 rounded-lg border-2 transition-all
                ${selectedCategory === cat.id
                  ? 'border-blue-500 bg-blue-50 text-blue-700'
                  : 'border-gray-200 hover:border-gray-300 text-gray-700'
                }
              `}
            >
              <span className="text-sm font-medium">{cat.name}</span>
            </button>
          ))}
        </div>
      </div>

      {/* Subcategories */}
      {category?.subcategories && (
        <div className="p-3">
          <div className="flex items-center space-x-2 mb-3 text-sm text-gray-500">
            <span>{category.name}</span>
            <ChevronRight size={16} />
            <span>Select Subcategory</span>
          </div>
          <div className="grid grid-cols-2 gap-2">
            {category.subcategories.map(subcat => (
              <button
                key={subcat.id}
                type="button" // Explicitly set button type
                onClick={(e) => handleSubcategoryClick(e, subcat.id)}
                className={`
                  text-left p-3 rounded-lg border transition-all
                  ${selectedSubcategory === subcat.id
                    ? 'border-blue-500 bg-blue-50 text-blue-700'
                    : 'border-gray-200 hover:border-gray-300 text-gray-700'
                  }
                `}
              >
                <span className="text-sm">{subcat.name}</span>
              </button>
            ))}
          </div>
        </div>
      )}

      {/* Specific Issues */}
      {subcategory?.subcategories && (
        <div className="p-3">
          <div className="flex items-center space-x-2 mb-3 text-sm text-gray-500">
            <span>{category?.name}</span>
            <ChevronRight size={16} />
            <span>{subcategory.name}</span>
            <ChevronRight size={16} />
            <span>Select Specific Issue</span>
          </div>
          <div className="space-y-2">
            {subcategory.subcategories.map(subsubcat => (
              <button
                key={subsubcat.id}
                type="button" // Explicitly set button type
                onClick={(e) => handleSubsubcategoryClick(e, subsubcat.id)}
                className={`
                  w-full text-left p-3 rounded-lg border transition-all
                  ${selectedSubsubcategory === subsubcat.id
                    ? 'border-blue-500 bg-blue-50 text-blue-700'
                    : 'border-gray-200 hover:border-gray-300 text-gray-700'
                  }
                `}
              >
                <span className="text-sm">{subsubcat.name}</span>
              </button>
            ))}
          </div>
        </div>
      )}

      {/* Selected Path Display */}
      {selectedCategory && (
        <div className="p-3 bg-gray-50">
          <div className="text-sm text-gray-600">
            Selected Category:
            <div className="font-medium text-gray-900 mt-1 flex items-center space-x-2">
              <span>{category?.name}</span>
              {selectedSubcategory && (
                <>
                  <ChevronRight size={14} className="text-gray-400" />
                  <span>{subcategory?.name}</span>
                </>
              )}
              {selectedSubsubcategory && (
                <>
                  <ChevronRight size={14} className="text-gray-400" />
                  <span>
                    {subcategory?.subcategories?.find(s => s.id === selectedSubsubcategory)?.name}
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}