import React from 'react';
import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { AlertTriangle, Trash2, Edit2 } from 'lucide-react';
import type { QualityIssue } from '../../../types/quality';

interface KanbanCardProps {
  issue: QualityIssue;
  onDelete: (id: string) => void;
  onEdit: (issue: QualityIssue) => void;
  isDragOverlay?: boolean;
}

export function KanbanCard({ issue, onDelete, onEdit, isDragOverlay }: KanbanCardProps) {
  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
    id: issue.id,
    data: issue
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: !isDragging ? 'transform 200ms ease-in-out' : undefined,
  };

  const getSeverityColor = (severity: QualityIssue['severity']) => {
    switch (severity) {
      case 'high':
        return 'bg-red-100 text-red-800 border-red-200';
      case 'medium':
        return 'bg-yellow-100 text-yellow-800 border-yellow-200';
      case 'low':
        return 'bg-blue-100 text-blue-800 border-blue-200';
    }
  };

  const handleDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    onDelete(issue.id);
  };

  const handleEdit = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    onEdit(issue);
  };

  const cardContent = (
    <>
      <div className="flex items-start justify-between mb-2">
        <h4 className="text-sm font-medium text-gray-900 line-clamp-1">{issue.title}</h4>
        <div className="flex items-center space-x-2 flex-shrink-0">
          <button
            onClick={handleEdit}
            className="opacity-0 group-hover:opacity-100 p-1 text-blue-400 hover:text-blue-600 transition-all"
            title="Edit issue"
          >
            <Edit2 className="flex-shrink-0" size={14} />
          </button>
          <button
            onClick={handleDelete}
            className="opacity-0 group-hover:opacity-100 p-1 text-red-400 hover:text-red-600 transition-all"
            title="Delete issue"
          >
            <Trash2 className="flex-shrink-0" size={14} />
          </button>
          <span className={`text-xs px-2 py-1 rounded-full whitespace-nowrap ${getSeverityColor(issue.severity)}`}>
            {issue.severity}
          </span>
        </div>
      </div>
      <p className="text-sm text-gray-600 line-clamp-2 mb-3">
        {issue.description}
      </p>
      <div className="flex items-center justify-between text-xs text-gray-500">
        {issue.assignedTo && (
          <span className="line-clamp-1">Assigned to: {issue.assignedTo}</span>
        )}
        <span className="flex-shrink-0">{new Date(issue.updatedAt).toLocaleDateString()}</span>
      </div>
    </>
  );

  if (isDragOverlay) {
    return (
      <div className="bg-white rounded-lg border border-gray-200 shadow-lg p-4 rotate-3">
        {cardContent}
      </div>
    );
  }

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className={`
        group bg-white rounded-lg border border-gray-200 shadow-sm p-4 
        cursor-grab active:cursor-grabbing hover:shadow-md transition-all
        ${isDragging ? 'opacity-50' : 'opacity-100'}
      `}
    >
      {cardContent}
    </div>
  );
}