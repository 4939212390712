import React from 'react';
import { Rocket, CheckCircle2, Clock, AlertTriangle } from 'lucide-react';
import { StatCard } from './StatCard';
import { StatItem } from './StatItem';
import type { Assembly } from '../../../types';

interface AssemblyStatsProps {
  assemblies: Assembly[];
}

export function AssemblyStats({ assemblies }: AssemblyStatsProps) {
  const totalAssemblies = assemblies.length;
  const completedAssemblies = assemblies.filter(a => 
    a.procedures.every(p => p.isComplete)
  ).length;
  const inProgressAssemblies = assemblies.filter(a => 
    a.procedures.some(p => p.currentStep > 0) && 
    !a.procedures.every(p => p.isComplete)
  ).length;
  const plannedAssemblies = totalAssemblies - completedAssemblies - inProgressAssemblies;

  return (
    <StatCard title="Assembly Status" icon={<Rocket className="text-indigo-600" size={24} />}>
      <StatItem
        icon={<CheckCircle2 className="text-green-500" size={20} />}
        label="Completed"
        value={completedAssemblies}
      />
      <StatItem
        icon={<Clock className="text-blue-500" size={20} />}
        label="In Progress"
        value={inProgressAssemblies}
      />
      <StatItem
        icon={<AlertTriangle className="text-yellow-500" size={20} />}
        label="Planned"
        value={plannedAssemblies}
      />
    </StatCard>
  );
}