import React from 'react';
import { Cable, Network, Box, GitBranch } from 'lucide-react';
import { StatCard } from './StatCard';
import { StatItem } from './StatItem';
import type { Harness } from '../../../types';

interface HarnessStatsProps {
  harnesses: Harness[];
}

export function HarnessStats({ harnesses }: HarnessStatsProps) {
  // Calculate metrics
  const totalHarnesses = harnesses.length;
  const totalConnections = harnesses.reduce((acc, h) => acc + (h.connections?.length || 0), 0);
  const totalBoxes = harnesses.reduce((acc, h) => acc + (h.electricalBoxes?.length || 0), 0);
  const totalPorts = harnesses.reduce((acc, h) => 
    acc + h.electricalBoxes?.reduce((boxAcc, box) => boxAcc + (box.ports?.length || 0), 0) || 0
  , 0);

  // Calculate connection types
  const connectionTypes = harnesses.reduce((acc, h) => {
    h.connections?.forEach(conn => {
      const sourceBox = h.electricalBoxes?.find(box => box.id === conn.sourceBoxId);
      const sourcePort = sourceBox?.ports?.find(port => port.id === conn.sourcePortId);
      if (sourcePort?.type) {
        acc[sourcePort.type] = (acc[sourcePort.type] || 0) + 1;
      }
    });
    return acc;
  }, {} as Record<string, number>);

  return (
    <StatCard title="Harness Overview" icon={<Cable className="text-indigo-600" size={24} />}>
      <div className="space-y-6">
        {/* Main Stats */}
        <div className="grid gap-2">
          <StatItem 
            icon={<Network className="text-blue-500" size={20} />}
            label="Total Harnesses" 
            value={totalHarnesses} 
          />
          <StatItem 
            icon={<Box className="text-purple-500" size={20} />}
            label="Electrical Boxes" 
            value={totalBoxes} 
          />
          <StatItem 
            icon={<GitBranch className="text-cyan-500" size={20} />}
            label="Total Connections" 
            value={totalConnections} 
          />
        </div>

        {/* Port Stats */}
        <div className="space-y-2">
          <h4 className="text-sm font-medium text-gray-700">Port Overview</h4>
          <div className="bg-gray-50 rounded-lg p-3">
            <div className="flex justify-between items-center mb-2">
              <span className="text-sm text-gray-600">Total Ports</span>
              <span className="font-medium">{totalPorts}</span>
            </div>
            <div className="space-y-1">
              <div className="flex justify-between items-center text-sm">
                <span className="text-gray-600">Input</span>
                <span>{connectionTypes.input || 0}</span>
              </div>
              <div className="flex justify-between items-center text-sm">
                <span className="text-gray-600">Output</span>
                <span>{connectionTypes.output || 0}</span>
              </div>
              <div className="flex justify-between items-center text-sm">
                <span className="text-gray-600">Bidirectional</span>
                <span>{connectionTypes.bidirectional || 0}</span>
              </div>
            </div>
          </div>
        </div>

        {/* Connection Health */}
        <div className="space-y-2">
          <h4 className="text-sm font-medium text-gray-700">Connection Health</h4>
          <div className="w-full bg-gray-200 rounded-full h-2">
            <div
              className="bg-green-500 h-2 rounded-full transition-all duration-300"
              style={{ 
                width: totalConnections > 0 
                  ? '100%' 
                  : '0%' 
              }}
            />
          </div>
          <div className="flex justify-between text-xs text-gray-500">
            <span>All connections stable</span>
            <span>{totalConnections} total</span>
          </div>
        </div>
      </div>
    </StatCard>
  );
}