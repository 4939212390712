import React, { useState } from 'react';
import { CheckCircle2, Circle, Edit2, Trash2, X, Save } from 'lucide-react';
import type { QualityIssue } from '../../../types/quality';

interface ActionItemProps {
  action: QualityIssue['resolutionSteps'][0]['actionSteps'][0];
  onUpdate?: (actionId: string, text: string) => void;
  onDelete?: (actionId: string) => void;
  onToggle?: (actionId: string) => void;
}

export function ActionItem({ action, onUpdate, onDelete, onToggle }: ActionItemProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [editText, setEditText] = useState(action.text);

  const handleSave = () => {
    if (editText.trim() && onUpdate) {
      onUpdate(action.id, editText.trim());
    }
    setIsEditing(false);
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSave();
    }
    if (e.key === 'Escape') {
      setIsEditing(false);
      setEditText(action.text);
    }
  };

  return (
    <div className="group flex items-start space-x-3 p-2 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors">
      <button
        onClick={() => onToggle?.(action.id)}
        className="mt-0.5 flex-shrink-0"
      >
        {action.isComplete ? (
          <CheckCircle2 className="text-green-500" size={16} />
        ) : (
          <Circle className="text-gray-400" size={16} />
        )}
      </button>

      <div className="flex-1 min-w-0">
        {isEditing ? (
          <div className="flex items-center space-x-2">
            <input
              type="text"
              value={editText}
              onChange={(e) => setEditText(e.target.value)}
              onKeyDown={handleKeyPress}
              className="flex-1 px-2 py-1 text-sm border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              autoFocus
            />
            <button
              onClick={handleSave}
              className="p-1 text-green-600 hover:text-green-700"
              title="Save changes"
            >
              <Save size={14} />
            </button>
            <button
              onClick={() => {
                setIsEditing(false);
                setEditText(action.text);
              }}
              className="p-1 text-gray-400 hover:text-gray-500"
              title="Cancel"
            >
              <X size={14} />
            </button>
          </div>
        ) : (
          <span className={`text-sm ${
            action.isComplete ? 'text-gray-500 line-through' : 'text-gray-900'
          }`}>
            {action.text}
          </span>
        )}
      </div>

      {!isEditing && (
        <div className="flex items-center space-x-1 opacity-0 group-hover:opacity-100 transition-opacity">
          <button
            onClick={() => setIsEditing(true)}
            className="p-1 text-blue-400 hover:text-blue-600"
            title="Edit action"
          >
            <Edit2 size={14} />
          </button>
          <button
            onClick={() => onDelete?.(action.id)}
            className="p-1 text-red-400 hover:text-red-600"
            title="Delete action"
          >
            <Trash2 size={14} />
          </button>
        </div>
      )}
    </div>
  );
}