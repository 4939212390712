import React, { useState } from 'react';
import { Plus } from 'lucide-react';

interface AddActionStepProps {
  onAdd: (text: string) => void;
}

export function AddActionStep({ onAdd }: AddActionStepProps) {
  const [text, setText] = useState('');

  const handleSubmit = () => {
    if (!text.trim()) return;
    onAdd(text);
    setText('');
  };

  return (
    <div className="flex items-center space-x-2">
      <input
        type="text"
        value={text}
        onChange={(e) => setText(e.target.value)}
        placeholder="Add new action step..."
        className="flex-1 px-3 py-2 border border-gray-300 rounded-md"
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            handleSubmit();
          }
        }}
      />
      <button
        onClick={handleSubmit}
        disabled={!text.trim()}
        className="p-2 text-blue-600 hover:text-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
      >
        <Plus size={20} />
      </button>
    </div>
  );
}