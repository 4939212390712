import React from 'react';
import type { QualityIssue } from '../../../../types/quality';

interface TrendChartProps {
  issues: QualityIssue[];
}

export function TrendChart({ issues }: TrendChartProps) {
  const severityTrends = issues.reduce((acc, issue) => {
    const month = new Date(issue.createdAt).toLocaleDateString('en-US', { 
      month: 'short',
      year: 'numeric'
    });
    
    if (!acc[month]) {
      acc[month] = { high: 0, medium: 0, low: 0 };
    }
    acc[month][issue.severity]++;
    return acc;
  }, {} as Record<string, Record<string, number>>);

  const months = Object.keys(severityTrends).slice(-6);
  const maxValue = Math.max(
    ...Object.values(severityTrends).flatMap(counts => Object.values(counts))
  );

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <h3 className="text-lg font-medium text-gray-900 mb-6">Severity Trends</h3>
      
      <div className="space-y-6">
        {['high', 'medium', 'low'].map(severity => (
          <div key={severity} className="space-y-2">
            <div className="flex items-center space-x-2">
              <div className={`w-3 h-3 rounded-full ${
                severity === 'high' ? 'bg-red-500' :
                severity === 'medium' ? 'bg-yellow-500' :
                'bg-blue-500'
              }`} />
              <span className="text-sm font-medium text-gray-700 capitalize">
                {severity} Severity
              </span>
            </div>
            
            <div className="grid grid-cols-6 gap-2">
              {months.map(month => (
                <div key={month} className="space-y-1">
                  <div className="h-24 bg-gray-100 rounded relative">
                    <div
                      className={`absolute bottom-0 inset-x-0 ${
                        severity === 'high' ? 'bg-red-500' :
                        severity === 'medium' ? 'bg-yellow-500' :
                        'bg-blue-500'
                      } rounded`}
                      style={{
                        height: `${(severityTrends[month][severity] / maxValue) * 100}%`
                      }}
                    />
                  </div>
                  <div className="text-xs text-gray-600 text-center">
                    {month}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}