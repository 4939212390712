import React from 'react';
import { AlertTriangle, Clock, CheckCircle2 } from 'lucide-react';
import type { QualityIssue } from '../../types/quality';

interface QualityIssuesGridProps {
  issues: QualityIssue[];
  onSelectIssue: (issue: QualityIssue) => void;
}

export function QualityIssuesGrid({ issues, onSelectIssue }: QualityIssuesGridProps) {
  const getSeverityColor = (severity: QualityIssue['severity']) => {
    switch (severity) {
      case 'high':
        return 'bg-red-100 text-red-800';
      case 'medium':
        return 'bg-yellow-100 text-yellow-800';
      case 'low':
        return 'bg-blue-100 text-blue-800';
    }
  };

  const getStatusIcon = (status: QualityIssue['status']) => {
    switch (status) {
      case 'open':
        return <AlertTriangle className="text-yellow-500" size={16} />;
      case 'in-progress':
        return <Clock className="text-blue-500" size={16} />;
      case 'resolved':
        return <CheckCircle2 className="text-green-500" size={16} />;
    }
  };

  return (
    <div className="w-full h-full bg-white rounded-lg shadow-lg overflow-hidden flex flex-col">
      {/* Grid Headers */}
      <div className="grid grid-cols-[1fr_1fr_1fr_2fr_3fr_1fr_1fr_1fr_1fr] gap-4 p-4 bg-gray-50 border-b border-gray-200">
        <div className="font-medium text-gray-700">Issue #</div>
        <div className="font-medium text-gray-700">Part #</div>
        <div className="font-medium text-gray-700">Serial #</div>
        <div className="font-medium text-gray-700">Title</div>
        <div className="font-medium text-gray-700">Description</div>
        <div className="font-medium text-gray-700">Severity</div>
        <div className="font-medium text-gray-700">Status</div>
        <div className="font-medium text-gray-700">Assigned To</div>
        <div className="font-medium text-gray-700">Last Updated</div>
      </div>

      {/* Grid Rows */}
      <div className="flex-1 overflow-auto">
        <div className="divide-y divide-gray-200">
          {issues.map((issue) => (
            <div
              key={issue.id}
              onClick={() => onSelectIssue(issue)}
              className="grid grid-cols-[1fr_1fr_1fr_2fr_3fr_1fr_1fr_1fr_1fr] gap-4 p-4 hover:bg-gray-50 cursor-pointer transition-colors"
            >
              <div className="font-medium text-gray-900">{issue.issueNumber}</div>
              <div className="text-gray-600">{issue.partNumber}</div>
              <div className="text-gray-600">{issue.serialNumber}</div>
              <div className="font-medium text-gray-900 truncate">{issue.title}</div>
              <div className="text-gray-600 truncate">{issue.description}</div>
              <div>
                <span className={`inline-flex px-2 py-1 rounded-full text-xs font-medium ${getSeverityColor(issue.severity)}`}>
                  {issue.severity}
                </span>
              </div>
              <div className="flex items-center space-x-2">
                {getStatusIcon(issue.status)}
                <span className="text-sm text-gray-600 capitalize">
                  {issue.status.replace('-', ' ')}
                </span>
              </div>
              <div className="text-sm text-gray-600 truncate">
                {issue.assignedTo || 'Unassigned'}
              </div>
              <div className="text-sm text-gray-600">
                {new Date(issue.updatedAt).toLocaleDateString()}
              </div>
            </div>
          ))}

          {issues.length === 0 && (
            <div className="p-8 text-center text-gray-500">
              No quality issues found
            </div>
          )}
        </div>
      </div>
    </div>
  );
}