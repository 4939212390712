import React from 'react';
import { ListChecks } from 'lucide-react';
import type { QualityIssue } from '../../../../types/quality';

interface ActionStepStatsProps {
  issues: QualityIssue[];
}

export function ActionStepStats({ issues }: ActionStepStatsProps) {
  // Calculate actions per step
  const actionsByStep = issues.reduce((acc, issue) => {
    issue.resolutionSteps.forEach(step => {
      if (!acc[step.step]) {
        acc[step.step] = {
          total: 0,
          completed: 0,
          title: step.title
        };
      }
      const actionSteps = step.actionSteps || [];
      acc[step.step].total += actionSteps.length;
      acc[step.step].completed += actionSteps.filter(a => a.isComplete).length;
    });
    return acc;
  }, {} as Record<number, { total: number; completed: number; title: string }>);

  const totalActions = Object.values(actionsByStep).reduce((sum, step) => sum + step.total, 0);
  const completedActions = Object.values(actionsByStep).reduce((sum, step) => sum + step.completed, 0);
  const openActions = totalActions - completedActions;

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-lg font-medium text-gray-900">Action Steps</h3>
        <ListChecks className="text-indigo-600" size={24} />
      </div>

      <div className="space-y-6">
        {/* Overall Progress */}
        <div>
          <div className="flex justify-between items-center mb-2">
            <span className="text-sm font-medium text-gray-600">Overall Progress</span>
            <span className="text-sm text-gray-900">
              {completedActions}/{totalActions} complete
            </span>
          </div>
          <div className="w-full bg-gray-200 rounded-full h-2">
            <div
              className="bg-indigo-600 h-2 rounded-full transition-all duration-300"
              style={{ width: `${totalActions > 0 ? (completedActions / totalActions) * 100 : 0}%` }}
            />
          </div>
        </div>

        {/* Open Actions Summary */}
        <div className="bg-blue-50 rounded-lg p-4">
          <div className="text-blue-900 font-medium">Open Actions</div>
          <div className="text-2xl font-bold text-blue-700">{openActions}</div>
        </div>

        {/* Steps Breakdown */}
        <div className="space-y-3">
          {Object.entries(actionsByStep).map(([step, data]) => (
            <div key={step} className="space-y-1">
              <div className="flex justify-between text-sm">
                <span className="text-gray-600">Step {step}: {data.title}</span>
                <span className="font-medium">{data.total - data.completed}</span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-1.5">
                <div
                  className="bg-blue-500 h-1.5 rounded-full transition-all duration-300"
                  style={{ width: `${data.total > 0 ? (data.completed / data.total) * 100 : 0}%` }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}