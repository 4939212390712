import React from 'react';
import { Palette, Monitor, Stars } from 'lucide-react';
import { useTheme } from '../../contexts/ThemeContext';

export function AppearanceSettings() {
  const { theme, setTheme } = useTheme();

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center space-x-2">
        <Palette className="text-indigo-600" size={20} />
        <span>Appearance</span>
      </h3>

      <div className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-3">Theme</label>
          <div className="grid grid-cols-3 gap-4">
            {[
              { id: 'default', label: 'Default Mode', icon: Monitor },
              { id: 'space', label: 'Space Mode', icon: Stars },
            ].map((option) => {
              const Icon = option.icon;
              const isSelected = theme === option.id;
              
              return (
                <button
                  key={option.id}
                  onClick={() => setTheme(option.id)}
                  className={`
                    relative p-4 border-2 rounded-lg transition-all
                    ${isSelected 
                      ? 'border-blue-500 bg-blue-50' 
                      : 'border-gray-200 hover:border-gray-300'
                    }
                  `}
                >
                  <div className="flex flex-col items-center space-y-2">
                    <Icon 
                      size={24} 
                      className={isSelected ? 'text-blue-500' : 'text-gray-500'} 
                    />
                    <span className={`text-sm ${
                      isSelected ? 'text-blue-700' : 'text-gray-600'
                    }`}>
                      {option.label}
                    </span>
                  </div>
                </button>
              );
            })}
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">Display Density</label>
          <div className="mt-2 space-y-2">
            <label className="inline-flex items-center">
              <input type="radio" name="density" className="form-radio text-indigo-600" defaultChecked />
              <span className="ml-2">Comfortable</span>
            </label>
            <label className="inline-flex items-center">
              <input type="radio" name="density" className="form-radio text-indigo-600" />
              <span className="ml-2">Compact</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}