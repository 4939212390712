import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocalStorage } from '../hooks/useLocalStorage';

type Theme = 'default' | 'space';

interface ThemeContextType {
  theme: Theme;
  setTheme: (theme: Theme) => void;
}

const ThemeContext = createContext<ThemeContextType | null>(null);

export function ThemeProvider({ children }: { children: React.ReactNode }) {
  const [theme, setTheme] = useLocalStorage<Theme>('theme', 'default');
  const [stars, setStars] = useState<Array<{ id: number; x: number; y: number; size: number; opacity: number }>>([]);

  // Generate random stars when space theme is activated
  useEffect(() => {
    if (theme === 'space') {
      const newStars = Array.from({ length: 100 }, (_, i) => ({
        id: i,
        x: Math.random() * 100,
        y: Math.random() * 100,
        size: Math.random() * 2 + 1,
        opacity: Math.random() * 0.5 + 0.3
      }));
      setStars(newStars);
    }
  }, [theme]);

  // Apply theme classes to body
  useEffect(() => {
    document.body.className = theme === 'space' 
      ? 'bg-gradient-to-br from-gray-900 via-indigo-900 to-gray-900 text-white transition-colors duration-300'
      : 'bg-gradient-to-br from-indigo-50 to-blue-50 transition-colors duration-300';
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {theme === 'space' && (
        <div className="fixed inset-0 pointer-events-none overflow-hidden">
          {stars.map(star => (
            <div
              key={star.id}
              className="absolute rounded-full bg-white animate-twinkle"
              style={{
                left: `${star.x}%`,
                top: `${star.y}%`,
                width: `${star.size}px`,
                height: `${star.size}px`,
                opacity: star.opacity,
                animationDelay: `${Math.random() * 3}s`
              }}
            />
          ))}
        </div>
      )}
      {children}
    </ThemeContext.Provider>
  );
}

export function useTheme() {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
}