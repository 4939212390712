export const DEFAULT_RESOLUTION_STEPS = [
  {
    step: 1,
    title: 'Immediate Assessment',
    description: 'Conduct immediate analysis of the issue and impact',
    isComplete: false,
    actionSteps: []
  },
  {
    step: 2,
    title: 'Risk Mitigation',
    description: 'Implement temporary measures to reduce immediate risks',
    isComplete: false,
    actionSteps: []
  },
  {
    step: 3,
    title: 'Root Cause Analysis',
    description: 'Perform detailed investigation to identify root causes',
    isComplete: false,
    actionSteps: []
  },
  {
    step: 4,
    title: 'Action Plan',
    description: 'Develop comprehensive action plan with clear ownership',
    isComplete: false,
    actionSteps: []
  },
  {
    step: 5,
    title: 'Implementation',
    description: 'Execute corrective actions with proper validation',
    isComplete: false,
    actionSteps: []
  },
  {
    step: 6,
    title: 'Verification',
    description: 'Verify effectiveness of implemented solutions',
    isComplete: false,
    actionSteps: []
  }
] as const;