import React from 'react';
import { AlertTriangle, Clock, CheckCircle2, Activity } from 'lucide-react';
import type { QualityIssue } from '../../../../types/quality';

interface OverallStatsProps {
  issues: QualityIssue[];
}

export function OverallStats({ issues }: OverallStatsProps) {
  const totalIssues = issues.length;
  const openIssues = issues.filter(i => i.status === 'open').length;
  const inProgressIssues = issues.filter(i => i.status === 'in-progress').length;
  const resolvedIssues = issues.filter(i => i.status === 'resolved').length;

  const stats = [
    {
      label: 'Total Issues',
      value: totalIssues,
      icon: Activity,
      color: 'text-blue-600',
      bgColor: 'bg-blue-50',
    },
    {
      label: 'Open Issues',
      value: openIssues,
      icon: AlertTriangle,
      color: 'text-yellow-600',
      bgColor: 'bg-yellow-50',
    },
    {
      label: 'In Progress',
      value: inProgressIssues,
      icon: Clock,
      color: 'text-indigo-600',
      bgColor: 'bg-indigo-50',
    },
    {
      label: 'Resolved',
      value: resolvedIssues,
      icon: CheckCircle2,
      color: 'text-green-600',
      bgColor: 'bg-green-50',
    },
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
      {stats.map(({ label, value, icon: Icon, color, bgColor }) => (
        <div key={label} className={`${bgColor} rounded-lg p-6`}>
          <div className="flex items-center justify-between">
            <div>
              <p className="text-gray-600 text-sm">{label}</p>
              <p className={`text-2xl font-bold ${color} mt-1`}>{value}</p>
            </div>
            <Icon className={color} size={24} />
          </div>
        </div>
      ))}
    </div>
  );
}