// Application sections
export const SECTIONS = {
  DASHBOARD: 'dashboard',
  ASSEMBLIES: 'satellites',
  PROCEDURES: 'procedures',
  HARNESSES: 'harnesses',
  QUALITY: 'quality',
} as const;

// Entity names
export const ENTITY_NAMES = {
  ASSEMBLY: 'Assembly',
  PROCEDURE: 'Procedure',
  HARNESS: 'Harness',
  QUALITY: 'Quality',
} as const;

// Status colors
export const STATUS_COLORS = {
  planned: 'bg-yellow-100 text-yellow-800',
  'in-progress': 'bg-blue-100 text-blue-800',
  complete: 'bg-green-100 text-green-800',
} as const;

// Port configurations
export const PORT_SHAPES = [
  { id: 'circle', label: 'Circular' },
  { id: 'rectangle', label: 'Rectangle' },
] as const;

export const PORT_COLORS = [
  { id: 'green', label: 'Green', value: '#10B981' },
  { id: 'blue', label: 'Blue', value: '#3B82F6' },
  { id: 'black', label: 'Black', value: '#1F2937' },
  { id: 'silver', label: 'Silver', value: '#9CA3AF' },
] as const;

// File size limits
export const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes

// CSV configuration
export const CSV_REQUIREMENTS = {
  HEADERS: ['Assembly Name', 'Child Assemblies', 'Procedures'],
  DELIMITER: ',',
  MAX_SIZE: MAX_FILE_SIZE,
  ALLOWED_EXTENSIONS: ['.csv'],
} as const;