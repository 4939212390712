import { parseAssemblyCSV } from './assemblyCSVParser';
import type { AssemblyCSVData, ValidationResult } from '../../types/csv';

export async function validateAssemblyCSV(file: File): Promise<void> {
  const text = await file.text();
  const firstLine = text.split('\n')[0].trim();
  const expectedHeaders = ['Assembly Name', 'Child Assemblies', 'Procedures'];
  
  // Validate headers
  const headers = firstLine.split(',').map(h => h.trim());
  const missingHeaders = expectedHeaders.filter(
    expected => !headers.some(h => h.toLowerCase() === expected.toLowerCase())
  );

  if (missingHeaders.length > 0) {
    throw new Error(
      `Missing required columns: ${missingHeaders.join(', ')}. CSV must contain exactly these columns: ${expectedHeaders.join(', ')}`
    );
  }

  if (headers.length > expectedHeaders.length) {
    throw new Error(
      `CSV contains extra columns. Only these columns are allowed: ${expectedHeaders.join(', ')}`
    );
  }
}

export function validateAssemblyData(
  data: AssemblyCSVData[], 
  existingAssemblies: string[]
): ValidationResult {
  const errors: string[] = [];
  const warnings: string[] = [];
  const seenNames = new Set<string>();
  const implicitAssemblies = new Set<string>();

  data.forEach(row => {
    if (row.assemblyName) {
      seenNames.add(row.assemblyName);
    }
    row.childAssemblies.forEach(childName => {
      if (!seenNames.has(childName) && !existingAssemblies.includes(childName)) {
        implicitAssemblies.add(childName);
      }
    });
  });

  implicitAssemblies.forEach(name => seenNames.add(name));

  data.forEach((row, index) => {
    const rowNum = index + 1;

    if (!row.assemblyName) {
      errors.push(`Row ${rowNum}: Assembly Name is required`);
      return;
    }

    const duplicateInCsv = data.filter(r => r.assemblyName === row.assemblyName).length > 1;
    if (duplicateInCsv) {
      errors.push(`Row ${rowNum} (${row.assemblyName}): Duplicate Assembly Name in CSV`);
    }

    if (existingAssemblies.includes(row.assemblyName)) {
      warnings.push(`Row ${rowNum} (${row.assemblyName}): Assembly already exists and will be updated`);
    }

    row.childAssemblies.forEach(childName => {
      if (implicitAssemblies.has(childName)) {
        warnings.push(`Row ${rowNum} (${row.assemblyName}): Child Assembly "${childName}" will be created automatically`);
      }
    });
  });

  return { errors, warnings };
}