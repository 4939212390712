import React from 'react';
import { AlertTriangle, FileText, Settings, Sliders, Clock, List } from 'lucide-react';
import { QualityCategories } from './QualityCategories';

export function QualitySettings() {
  return (
    <div className="bg-white rounded-lg shadow p-6">
      <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center space-x-2">
        <AlertTriangle className="text-indigo-600" size={20} />
        <span>Quality Settings</span>
      </h3>

      <div className="space-y-8">
        {/* Categories Management */}
        <div>
          <div className="flex items-center space-x-2 mb-4">
            <List className="text-blue-600" size={16} />
            <h4 className="text-sm font-medium text-gray-700">Category Management</h4>
          </div>
          <QualityCategories />
        </div>

        {/* Rest of the settings sections... */}
        {/* Issue Configuration */}
        <div>
          <div className="flex items-center space-x-2 mb-4">
            <Settings className="text-blue-600" size={16} />
            <h4 className="text-sm font-medium text-gray-700">Issue Configuration</h4>
          </div>
          
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Default Severity
              </label>
              <select
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                defaultValue="medium"
              >
                <option value="low">Low</option>
                <option value="medium">Medium</option>
                <option value="high">High</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Issue Number Format
              </label>
              <input
                type="text"
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                placeholder="QI-{number}"
                defaultValue="QI-{number}"
              />
            </div>
          </div>
        </div>

        {/* Templates */}
        <div>
          <div className="flex items-center space-x-2 mb-4">
            <FileText className="text-blue-600" size={16} />
            <h4 className="text-sm font-medium text-gray-700">Issue Templates</h4>
          </div>
          
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Default Description Template
              </label>
              <textarea
                className="w-full px-3 py-2 border border-gray-300 rounded-md font-mono text-sm"
                rows={4}
                defaultValue={`[Issue Description]
Describe the quality issue in detail.

[Steps to Reproduce]
1. 
2. 
3.`}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Resolution Template
              </label>
              <textarea
                className="w-full px-3 py-2 border border-gray-300 rounded-md font-mono text-sm"
                rows={4}
                defaultValue={`[Resolution Steps]
1. 
2. 
3.

[Verification Steps]
- `}
              />
            </div>
          </div>
        </div>

        {/* Workflow */}
        <div>
          <div className="flex items-center space-x-2 mb-4">
            <Sliders className="text-blue-600" size={16} />
            <h4 className="text-sm font-medium text-gray-700">Workflow Settings</h4>
          </div>
          
          <div className="space-y-3">
            <label className="flex items-center space-x-3">
              <input
                type="checkbox"
                className="h-4 w-4 text-blue-600 rounded"
                defaultChecked
              />
              <span className="text-sm text-gray-700">Require root cause analysis</span>
            </label>

            <label className="flex items-center space-x-3">
              <input
                type="checkbox"
                className="h-4 w-4 text-blue-600 rounded"
                defaultChecked
              />
              <span className="text-sm text-gray-700">Require resolution steps</span>
            </label>

            <label className="flex items-center space-x-3">
              <input
                type="checkbox"
                className="h-4 w-4 text-blue-600 rounded"
                defaultChecked
              />
              <span className="text-sm text-gray-700">Require impact assessment</span>
            </label>
          </div>
        </div>

        {/* Review Process */}
        <div>
          <div className="flex items-center space-x-2 mb-4">
            <Clock className="text-blue-600" size={16} />
            <h4 className="text-sm font-medium text-gray-700">Review Process</h4>
          </div>
          
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Auto-close After (days)
              </label>
              <input
                type="number"
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                defaultValue={30}
                min={0}
              />
            </div>

            <label className="flex items-center space-x-3">
              <input
                type="checkbox"
                className="h-4 w-4 text-blue-600 rounded"
                defaultChecked
              />
              <span className="text-sm text-gray-700">Require review before closing</span>
            </label>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Default Reviewers
              </label>
              <input
                type="text"
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                placeholder="Enter usernames separated by commas"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}