import React, { useRef } from 'react';
import { AlertTriangle, CheckCircle2, Clock, User, Calendar, Edit2, Trash2, Hash, Box, Barcode, X } from 'lucide-react';
import { ResolutionSteps } from './resolution/ResolutionSteps';
import { useClickOutside } from '../../hooks/useClickOutside';
import type { QualityIssue } from '../../types/quality';

interface QualityIssueDetailProps {
  issue: QualityIssue;
  onClose: () => void;
  onEdit: (issue: QualityIssue) => void;
  onDelete: () => void;
}

export function QualityIssueDetail({ issue, onClose, onEdit, onDelete }: QualityIssueDetailProps) {
  const modalRef = useRef<HTMLDivElement>(null);
  useClickOutside(modalRef, onClose);

  const handleEdit = () => {
    // Pass the complete issue object to the edit handler
    onEdit({
      ...issue,
      resolutionSteps: issue.resolutionSteps ? [...issue.resolutionSteps] : []
    });
  };

  const getSeverityColor = (severity: QualityIssue['severity']) => {
    switch (severity) {
      case 'high':
        return 'bg-red-100 text-red-800 border-red-200';
      case 'medium':
        return 'bg-yellow-100 text-yellow-800 border-yellow-200';
      case 'low':
        return 'bg-blue-100 text-blue-800 border-blue-200';
    }
  };

  const getStatusIcon = (status: QualityIssue['status']) => {
    switch (status) {
      case 'open':
        return <AlertTriangle className="text-yellow-500" size={20} />;
      case 'in-progress':
        return <Clock className="text-blue-500" size={20} />;
      case 'resolved':
        return <CheckCircle2 className="text-green-500" size={20} />;
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-6 z-50 overflow-y-auto">
      <div 
        ref={modalRef}
        className="bg-white rounded-xl shadow-xl w-full max-w-6xl max-h-[90vh] overflow-hidden flex flex-col"
      >
        {/* Header */}
        <div className="flex-none px-6 py-4 border-b border-gray-200">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <h2 className="text-xl font-bold text-gray-900">Issue Details</h2>
              <div className="flex items-center space-x-2">
                <span className={`px-3 py-1 rounded-full text-sm font-medium ${getSeverityColor(issue.severity)}`}>
                  {issue.severity}
                </span>
                <span className={`px-3 py-1 rounded-full text-sm font-medium ${
                  issue.status === 'resolved' 
                    ? 'bg-green-100 text-green-800' 
                    : issue.status === 'in-progress'
                    ? 'bg-blue-100 text-blue-800'
                    : 'bg-yellow-100 text-yellow-800'
                }`}>
                  {issue.status.replace('-', ' ')}
                </span>
              </div>
            </div>
            <button
              onClick={onClose}
              className="p-2 text-gray-400 hover:text-gray-500 transition-colors"
            >
              <X size={20} />
            </button>
          </div>
        </div>

        {/* Content */}
        <div className="flex-1 overflow-y-auto">
          <div className="grid grid-cols-3 gap-6 p-6">
            {/* Main Info */}
            <div className="space-y-6">
              <div className="flex items-start justify-between">
                <div>
                  <h3 className="text-xl font-bold text-gray-900">{issue.title}</h3>
                  <p className="text-sm text-gray-600 mt-1">Issue #{issue.issueNumber}</p>
                </div>
                <div className="flex space-x-2">
                  <button
                    onClick={handleEdit}
                    className="p-2 text-gray-400 hover:text-blue-600 transition-colors"
                    title="Edit issue"
                  >
                    <Edit2 size={20} />
                  </button>
                  <button
                    onClick={onDelete}
                    className="p-2 text-gray-400 hover:text-red-600 transition-colors"
                    title="Delete issue"
                  >
                    <Trash2 size={20} />
                  </button>
                </div>
              </div>

              {/* Identification Numbers */}
              <div className="space-y-4">
                <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-4">
                  <div className="flex items-center space-x-2 text-gray-600">
                    <Hash size={16} />
                    <span className="text-sm font-medium">Issue Number:</span>
                    <span>{issue.issueNumber}</span>
                  </div>
                </div>

                <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-4">
                  <div className="flex items-center space-x-2 text-gray-600">
                    <Box size={16} />
                    <span className="text-sm font-medium">Part Number:</span>
                    <span>{issue.partNumber}</span>
                  </div>
                </div>

                <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-4">
                  <div className="flex items-center space-x-2 text-gray-600">
                    <Barcode size={16} />
                    <span className="text-sm font-medium">Serial Number:</span>
                    <span>{issue.serialNumber}</span>
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
                <h2 className="text-lg font-medium text-gray-900 mb-4">Description</h2>
                <p className="text-gray-600 whitespace-pre-wrap">{issue.description}</p>
              </div>

              <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-4 space-y-4">
                <div className="flex items-center space-x-2 text-gray-600">
                  <User size={16} />
                  <span className="text-sm font-medium">Assigned To:</span>
                  <span>{issue.assignedTo || 'Unassigned'}</span>
                </div>

                <div className="flex items-center space-x-2 text-gray-600">
                  <Calendar size={16} />
                  <span className="text-sm font-medium">Dates:</span>
                  <div className="space-y-1 text-sm">
                    <p>Created: {new Date(issue.createdAt).toLocaleString()}</p>
                    <p>Updated: {new Date(issue.updatedAt).toLocaleString()}</p>
                  </div>
                </div>
              </div>
            </div>

            {/* Resolution Steps Section */}
            <div className="col-span-2 bg-white rounded-lg shadow-sm border border-gray-200">
              <ResolutionSteps issue={issue} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}