import React, { useState, useRef, useEffect } from 'react';
import { Plus, ChevronDown, ChevronRight, Edit2, Trash2 } from 'lucide-react';
import { QUALITY_CATEGORIES } from '../../../utils/quality/categories';
import { useClickOutside } from '../../../hooks/useClickOutside';
import type { QualityCategory } from '../../../types/quality';

interface CategoryItemProps {
  category: QualityCategory;
  level?: number;
  onEdit: (category: QualityCategory) => void;
  onDelete: (category: QualityCategory) => void;
}

function CategoryItem({ category, level = 0, onEdit, onDelete }: CategoryItemProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const hasSubcategories = category.subcategories && category.subcategories.length > 0;
  const paddingLeft = `${level * 1.5}rem`;

  return (
    <div>
      <div 
        className="flex items-center justify-between p-3 hover:bg-gray-50 rounded-lg transition-colors"
        style={{ paddingLeft }}
      >
        <div className="flex items-center space-x-2">
          {hasSubcategories ? (
            <button 
              onClick={() => setIsExpanded(!isExpanded)}
              className="text-gray-500 hover:text-gray-700"
            >
              {isExpanded ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
            </button>
          ) : (
            <div className="w-4" />
          )}
          <span className="text-sm font-medium text-gray-900">{category.name}</span>
        </div>
        <div className="flex items-center space-x-2">
          <button
            onClick={() => onEdit(category)}
            className="p-1 text-gray-400 hover:text-blue-600 transition-colors"
            title="Edit category"
          >
            <Edit2 size={14} />
          </button>
          <button
            onClick={() => onDelete(category)}
            className="p-1 text-gray-400 hover:text-red-600 transition-colors"
            title="Delete category"
          >
            <Trash2 size={14} />
          </button>
        </div>
      </div>

      {isExpanded && hasSubcategories && (
        <div>
          {category.subcategories.map(subcategory => (
            <CategoryItem
              key={subcategory.id}
              category={subcategory}
              level={level + 1}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          ))}
        </div>
      )}
    </div>
  );
}

interface CategoryFormProps {
  category?: QualityCategory;
  parentCategory?: QualityCategory;
  onSubmit: (data: Partial<QualityCategory>) => void;
  onCancel: () => void;
}

function CategoryForm({ category, parentCategory, onSubmit, onCancel }: CategoryFormProps) {
  const formRef = useRef<HTMLFormElement>(null);
  const [formData, setFormData] = useState({
    name: category?.name || '',
    id: category?.id || '',
  });

  useClickOutside(formRef, onCancel);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form 
      ref={formRef}
      onSubmit={handleSubmit} 
      className="space-y-4 p-4 bg-gray-50 rounded-lg border border-gray-200 shadow-sm"
    >
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Category Name
        </label>
        <input
          type="text"
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
          required
          autoFocus
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Category ID
        </label>
        <input
          type="text"
          value={formData.id}
          onChange={(e) => setFormData({ ...formData, id: e.target.value.toLowerCase().replace(/\s+/g, '-') })}
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
          required
          pattern="[a-z0-9-]+"
          title="Only lowercase letters, numbers, and hyphens allowed"
        />
      </div>

      {parentCategory && (
        <div className="text-sm text-gray-500">
          Parent Category: {parentCategory.name}
        </div>
      )}

      <div className="flex justify-end space-x-3">
        <button
          type="button"
          onClick={onCancel}
          className="px-3 py-2 text-gray-700 hover:text-gray-900"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="px-3 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
        >
          {category ? 'Update Category' : 'Add Category'}
        </button>
      </div>
    </form>
  );
}

export function QualityCategories() {
  const [categories, setCategories] = useState(QUALITY_CATEGORIES);
  const [editingCategory, setEditingCategory] = useState<QualityCategory | null>(null);
  const [showAddForm, setShowAddForm] = useState(false);

  const handleAddCategory = (data: Partial<QualityCategory>) => {
    const newCategory: QualityCategory = {
      id: data.id!,
      name: data.name!,
      subcategories: []
    };
    setCategories([...categories, newCategory]);
    setShowAddForm(false);
  };

  const handleEditCategory = (category: QualityCategory) => {
    setEditingCategory(category);
  };

  const handleUpdateCategory = (data: Partial<QualityCategory>) => {
    if (!editingCategory) return;

    const updateCategoryInTree = (cats: QualityCategory[]): QualityCategory[] => {
      return cats.map(cat => {
        if (cat.id === editingCategory.id) {
          return { ...cat, ...data };
        }
        if (cat.subcategories) {
          return {
            ...cat,
            subcategories: updateCategoryInTree(cat.subcategories)
          };
        }
        return cat;
      });
    };

    setCategories(updateCategoryInTree(categories));
    setEditingCategory(null);
  };

  const handleDeleteCategory = (category: QualityCategory) => {
    if (!window.confirm('Are you sure you want to delete this category?')) return;

    const deleteCategoryFromTree = (cats: QualityCategory[]): QualityCategory[] => {
      return cats.filter(cat => {
        if (cat.id === category.id) return false;
        if (cat.subcategories) {
          return {
            ...cat,
            subcategories: deleteCategoryFromTree(cat.subcategories)
          };
        }
        return true;
      });
    };

    setCategories(deleteCategoryFromTree(categories));
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h4 className="text-sm font-medium text-gray-700">Quality Categories</h4>
        <button
          onClick={() => setShowAddForm(true)}
          className="flex items-center space-x-1 text-sm text-blue-600 hover:text-blue-700"
        >
          <Plus size={16} />
          <span>Add Category</span>
        </button>
      </div>

      {showAddForm && (
        <CategoryForm
          onSubmit={handleAddCategory}
          onCancel={() => setShowAddForm(false)}
        />
      )}

      {editingCategory && (
        <CategoryForm
          category={editingCategory}
          onSubmit={handleUpdateCategory}
          onCancel={() => setEditingCategory(null)}
        />
      )}

      <div className="border rounded-lg divide-y">
        {categories.map(category => (
          <CategoryItem
            key={category.id}
            category={category}
            onEdit={handleEditCategory}
            onDelete={handleDeleteCategory}
          />
        ))}
      </div>
    </div>
  );
}