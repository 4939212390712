import React from 'react';
import { groupIssuesByTime } from '../../../../utils/quality/timelineUtils';
import type { QualityIssue } from '../../../../types/quality';

interface TimelineChartProps {
  issues: QualityIssue[];
}

export function TimelineChart({ issues }: TimelineChartProps) {
  const [timeframe, setTimeframe] = React.useState<'day' | 'week' | 'month'>('week');
  const groupedIssues = groupIssuesByTime(issues, timeframe);
  const maxCount = Math.max(...Object.values(groupedIssues));

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <div className="flex justify-between items-center mb-6">
        <h3 className="text-lg font-medium text-gray-900">Issues Over Time</h3>
        <div className="flex space-x-2">
          {(['day', 'week', 'month'] as const).map(period => (
            <button
              key={period}
              onClick={() => setTimeframe(period)}
              className={`px-3 py-1 rounded-lg text-sm ${
                timeframe === period
                  ? 'bg-blue-100 text-blue-700'
                  : 'text-gray-600 hover:bg-gray-100'
              }`}
            >
              {period.charAt(0).toUpperCase() + period.slice(1)}
            </button>
          ))}
        </div>
      </div>

      <div className="space-y-2">
        {Object.entries(groupedIssues).map(([label, count]) => (
          <div key={label} className="flex items-center space-x-2">
            <div className="w-24 text-sm text-gray-600">{label}</div>
            <div className="flex-1">
              <div className="relative h-8 bg-gray-100 rounded">
                <div
                  className="absolute inset-y-0 left-0 bg-blue-500 rounded"
                  style={{ width: `${(count / maxCount) * 100}%` }}
                />
                <div className="absolute inset-0 flex items-center px-2">
                  <span className="text-sm font-medium text-white z-10">
                    {count}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}