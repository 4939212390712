import React from 'react';
import { Box, Barcode } from 'lucide-react';
import type { QualityIssue } from '../../../../types/quality';

interface DistributionStatsProps {
  issues: QualityIssue[];
}

export function DistributionStats({ issues }: DistributionStatsProps) {
  const partNumberStats = issues.reduce((acc, issue) => {
    acc[issue.partNumber] = (acc[issue.partNumber] || 0) + 1;
    return acc;
  }, {} as Record<string, number>);

  const serialNumberStats = issues.reduce((acc, issue) => {
    acc[issue.serialNumber] = (acc[issue.serialNumber] || 0) + 1;
    return acc;
  }, {} as Record<string, number>);

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <h3 className="text-lg font-medium text-gray-900 mb-6">Distribution Analysis</h3>
      
      <div className="space-y-6">
        {/* Part Numbers */}
        <div>
          <div className="flex items-center space-x-2 mb-4">
            <Box className="text-blue-600" size={20} />
            <h4 className="text-sm font-medium text-gray-700">Issues by Part Number</h4>
          </div>
          <div className="space-y-2">
            {Object.entries(partNumberStats)
              .sort(([, a], [, b]) => b - a)
              .map(([partNumber, count]) => (
                <div key={partNumber} className="flex items-center">
                  <div className="w-32 text-sm text-gray-600">{partNumber}</div>
                  <div className="flex-1">
                    <div className="relative h-2 bg-gray-100 rounded-full">
                      <div 
                        className="absolute inset-y-0 left-0 bg-blue-500 rounded-full"
                        style={{ width: `${(count / issues.length) * 100}%` }}
                      />
                    </div>
                  </div>
                  <div className="w-12 text-right text-sm text-gray-600">{count}</div>
                </div>
              ))}
          </div>
        </div>

        {/* Serial Numbers */}
        <div>
          <div className="flex items-center space-x-2 mb-4">
            <Barcode className="text-indigo-600" size={20} />
            <h4 className="text-sm font-medium text-gray-700">Issues by Serial Number</h4>
          </div>
          <div className="space-y-2">
            {Object.entries(serialNumberStats)
              .sort(([, a], [, b]) => b - a)
              .map(([serialNumber, count]) => (
                <div key={serialNumber} className="flex items-center">
                  <div className="w-32 text-sm text-gray-600">{serialNumber}</div>
                  <div className="flex-1">
                    <div className="relative h-2 bg-gray-100 rounded-full">
                      <div 
                        className="absolute inset-y-0 left-0 bg-indigo-500 rounded-full"
                        style={{ width: `${(count / issues.length) * 100}%` }}
                      />
                    </div>
                  </div>
                  <div className="w-12 text-right text-sm text-gray-600">{count}</div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}