import React from 'react';
import { UserSettings } from './UserSettings';
import { AppearanceSettings } from './AppearanceSettings';
import { NotificationSettings } from './NotificationSettings';
import { SecuritySettings } from './SecuritySettings';
import { QualitySettings } from './sections/QualitySettings';

export function SettingsPanel() {
  return (
    <div className="space-y-6 p-6">
      <div className="border-b border-gray-200 pb-5">
        <h2 className="text-2xl font-bold text-gray-900">Settings</h2>
        <p className="mt-1 text-sm text-gray-500">
          Manage your preferences and account settings
        </p>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <UserSettings />
        <AppearanceSettings />
        <QualitySettings />
        <NotificationSettings />
        <SecuritySettings />
      </div>
    </div>
  );
}