import React from 'react';
import { CheckCircle2, Circle } from 'lucide-react';
import { ActionStepsList } from './ActionStepsList';
import { AddActionStep } from './AddActionStep';
import type { QualityIssue } from '../../../types/quality';

interface ResolutionStepDetailProps {
  step: QualityIssue['resolutionSteps'][0];
  onComplete?: (stepNumber: number) => void;
  onAddActionStep?: (stepNumber: number, text: string) => void;
  onToggleActionStep?: (stepNumber: number, actionStepId: string) => void;
  onDeleteActionStep?: (stepNumber: number, actionStepId: string) => void;
}

export function ResolutionStepDetail({ 
  step,
  onComplete,
  onAddActionStep,
  onToggleActionStep,
  onDeleteActionStep
}: ResolutionStepDetailProps) {
  return (
    <div className="p-6 space-y-6">
      <div className="flex items-center justify-between">
        <h3 className="text-xl font-bold text-gray-900">Step {step.step}: {step.title}</h3>
        <button
          onClick={() => onComplete?.(step.step)}
          className="p-2 rounded-full hover:bg-gray-100 transition-colors"
        >
          {step.isComplete ? (
            <CheckCircle2 className="text-green-500" size={24} />
          ) : (
            <Circle className="text-gray-400" size={24} />
          )}
        </button>
      </div>

      <div className="prose max-w-none">
        <p className="text-gray-600 whitespace-pre-wrap">{step.description}</p>
      </div>

      <div className="bg-blue-50 border border-blue-100 rounded-lg p-4">
        <h4 className="font-medium text-blue-900 mb-2">Guidance</h4>
        <ul className="list-disc list-inside space-y-2 text-blue-800">
          <li>Document all findings and observations</li>
          <li>Consult relevant technical documentation</li>
          <li>Involve necessary stakeholders</li>
          <li>Follow established procedures and protocols</li>
        </ul>
      </div>

      <div className="bg-gray-50 border border-gray-200 rounded-lg p-4">
        <h4 className="font-medium text-gray-900 mb-4">Action Steps</h4>
        
        <div className="space-y-4">
          <ActionStepsList
            actionSteps={step.actionSteps}
            onToggle={(actionStepId) => onToggleActionStep?.(step.step, actionStepId)}
            onDelete={(actionStepId) => onDeleteActionStep?.(step.step, actionStepId)}
          />

          <AddActionStep 
            onAdd={(text) => onAddActionStep?.(step.step, text)} 
          />
        </div>
      </div>
    </div>
  );
}