import React, { useState } from 'react';
import { useResolutionSteps } from '../../../hooks/useResolutionSteps';
import { ResolutionStepDetail } from './ResolutionStepDetail';
import type { QualityIssue } from '../../../types/quality';

interface ResolutionStepsProps {
  issue: QualityIssue;
}

export function ResolutionSteps({ issue }: ResolutionStepsProps) {
  const [selectedStep, setSelectedStep] = useState<QualityIssue['resolutionSteps'][0] | null>(null);
  const { steps, addActionStep, toggleActionStep, deleteActionStep } = useResolutionSteps(issue.resolutionSteps, issue.id);

  const handleStepClick = (step: QualityIssue['resolutionSteps'][0]) => {
    setSelectedStep(step === selectedStep ? null : step);
  };

  const handleStepComplete = (stepNumber: number) => {
    // Step completion is handled separately through the quality issue update
  };

  return (
    <div className="flex h-full divide-x divide-gray-200">
      {/* Steps List */}
      <div className="w-1/2 overflow-auto p-6">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl font-bold text-gray-900">Resolution Steps</h2>
          <div className="flex items-center space-x-2">
            {issue.status === 'resolved' ? (
              <span className="flex items-center space-x-1 text-green-600 text-sm">
                <span>Completed</span>
              </span>
            ) : (
              <span className="flex items-center space-x-1 text-blue-600 text-sm">
                <span>In Progress</span>
              </span>
            )}
          </div>
        </div>

        <div className="space-y-4">
          {steps.map((step) => (
            <div
              key={step.step}
              onClick={() => handleStepClick(step)}
              className={`
                relative flex items-start p-4 rounded-lg border-2 transition-all cursor-pointer
                ${selectedStep?.step === step.step
                  ? 'bg-blue-50 border-blue-200'
                  : step.isComplete
                  ? 'bg-green-50 border-green-200'
                  : 'bg-gray-50 border-gray-200 hover:bg-gray-100'
                }
              `}
            >
              <div className="flex-1">
                <div className="flex items-center justify-between">
                  <h3 className={`font-medium ${
                    step.isComplete ? 'text-green-900' :
                    selectedStep?.step === step.step ? 'text-blue-900' :
                    'text-gray-900'
                  }`}>
                    Step {step.step}: {step.title}
                  </h3>
                </div>
                <p className={`mt-1 text-sm ${
                  step.isComplete ? 'text-green-600' :
                  selectedStep?.step === step.step ? 'text-blue-600' :
                  'text-gray-600'
                }`}>
                  {step.description}
                </p>
                {step.actionSteps?.length > 0 && (
                  <div className="mt-2 text-sm text-gray-500">
                    {step.actionSteps.filter(a => a.isComplete).length} of {step.actionSteps.length} action steps complete
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Step Details */}
      <div className="w-1/2 overflow-auto">
        {selectedStep ? (
          <ResolutionStepDetail 
            step={selectedStep}
            onComplete={handleStepComplete}
            onAddActionStep={addActionStep}
            onToggleActionStep={toggleActionStep}
            onDeleteActionStep={deleteActionStep}
          />
        ) : (
          <div className="h-full flex items-center justify-center text-gray-500">
            <p>Select a step to view details</p>
          </div>
        )}
      </div>
    </div>
  );
}