import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { GripVertical, Trash2, Copy, Edit2 } from 'lucide-react';
import type { Satellite } from '../types';

interface SortableSatelliteItemProps {
  satellite: Satellite;
  isSelected: boolean;
  onSelect: (id: string) => void;
  onDelete: (id: string) => void;
  onDuplicate: (id: string) => void;
  onEdit: (id: string) => void;
}

export function SortableSatelliteItem({
  satellite,
  isSelected,
  onSelect,
  onDelete,
  onDuplicate,
  onEdit,
}: SortableSatelliteItemProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: satellite.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  const getStatusColor = (status: Satellite['status']) => {
    switch (status) {
      case 'planned':
        return 'bg-yellow-100 text-yellow-800';
      case 'in-progress':
        return 'bg-blue-100 text-blue-800';
      case 'complete':
        return 'bg-green-100 text-green-800';
    }
  };

  return (
    <div ref={setNodeRef} style={style}>
      <div
        className={`
          relative group rounded-lg p-3 cursor-pointer transition-all
          ${isSelected ? 'bg-blue-50 border-blue-200' : 'hover:bg-gray-50 border-transparent'}
          border
        `}
        onClick={() => onSelect(satellite.id)}
      >
        <div
          className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-2 opacity-0 group-hover:opacity-100 transition-opacity cursor-grab active:cursor-grabbing"
          {...attributes}
          {...listeners}
        >
          <GripVertical className="text-gray-400" />
        </div>

        <div className="flex justify-between items-start">
          <div>
            <div className="flex items-center space-x-2">
              <h3 className="font-medium text-gray-900">{satellite.name}</h3>
              <div className="flex space-x-1 opacity-0 group-hover:opacity-100 transition-opacity">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    onEdit(satellite.id);
                  }}
                  className="p-1 text-gray-400 hover:text-blue-500 transition-all"
                  title="Edit details"
                >
                  <Edit2 size={16} />
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    onDuplicate(satellite.id);
                  }}
                  className="p-1 text-gray-400 hover:text-blue-500 transition-all"
                  title="Duplicate assembly"
                >
                  <Copy size={16} />
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    onDelete(satellite.id);
                  }}
                  className="p-1 text-gray-400 hover:text-red-500 transition-all"
                  title="Delete assembly"
                >
                  <Trash2 size={16} />
                </button>
              </div>
            </div>
            {satellite.description && (
              <p className="text-sm text-gray-600 mt-0.5">
                {satellite.description}
              </p>
            )}
            <div className="flex items-center space-x-2 mt-2">
              <span
                className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${getStatusColor(
                  satellite.status
                )}`}
              >
                {satellite.status.replace('-', ' ')}
              </span>
              <span className="text-xs text-gray-500">
                {satellite.procedures.length} procedures
              </span>
              <span className="text-xs text-gray-500">
                {satellite.assemblies.length} assemblies
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}