import { useState, useCallback } from 'react';
import { doc, collection, updateDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { useAuth } from '../contexts/AuthContext';
import type { QualityIssue } from '../types/quality';

export function useResolutionSteps(initialSteps: QualityIssue['resolutionSteps'], issueId?: string) {
  const [steps, setSteps] = useState(initialSteps);
  const { currentUser } = useAuth();

  const updateFirestore = async (newSteps: typeof initialSteps) => {
    if (!currentUser || !issueId) return;

    const userRef = doc(db, 'users', currentUser.uid);
    const issueRef = doc(collection(userRef, 'quality-issues'), issueId);

    await updateDoc(issueRef, {
      resolutionSteps: newSteps,
      updatedAt: new Date()
    });
  };

  const addActionStep = useCallback(async (stepNumber: number, text: string) => {
    const newSteps = steps.map(step => {
      if (step.step === stepNumber) {
        return {
          ...step,
          actionSteps: [
            ...(step.actionSteps || []),
            {
              id: crypto.randomUUID(),
              text: text.trim(),
              isComplete: false
            }
          ]
        };
      }
      return step;
    });

    setSteps(newSteps);
    await updateFirestore(newSteps);
  }, [steps, currentUser, issueId]);

  const toggleActionStep = useCallback(async (stepNumber: number, actionStepId: string) => {
    const newSteps = steps.map(step => {
      if (step.step === stepNumber) {
        return {
          ...step,
          actionSteps: (step.actionSteps || []).map(actionStep =>
            actionStep.id === actionStepId
              ? { ...actionStep, isComplete: !actionStep.isComplete }
              : actionStep
          )
        };
      }
      return step;
    });

    setSteps(newSteps);
    await updateFirestore(newSteps);
  }, [steps, currentUser, issueId]);

  const deleteActionStep = useCallback(async (stepNumber: number, actionStepId: string) => {
    const newSteps = steps.map(step => {
      if (step.step === stepNumber) {
        return {
          ...step,
          actionSteps: (step.actionSteps || []).filter(actionStep => actionStep.id !== actionStepId)
        };
      }
      return step;
    });

    setSteps(newSteps);
    await updateFirestore(newSteps);
  }, [steps, currentUser, issueId]);

  return {
    steps,
    addActionStep,
    toggleActionStep,
    deleteActionStep
  };
}