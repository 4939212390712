import React from 'react';
import { ArrowUpDown } from 'lucide-react';

interface SortHeaderProps {
  onSort: () => void;
  direction: 'asc' | 'desc' | null;
}

export function SortHeader({ onSort, direction }: SortHeaderProps) {
  return (
    <button
      onClick={onSort}
      className="flex items-center space-x-2 px-4 py-2 text-gray-600 hover:text-gray-900 transition-colors"
    >
      <span>Sort by Issue #</span>
      <ArrowUpDown 
        size={16} 
        className={direction ? 'text-blue-600' : 'text-gray-400'} 
      />
      {direction && (
        <span className="text-xs text-blue-600">
          {direction === 'asc' ? '(Low to High)' : '(High to Low)'}
        </span>
      )}
    </button>
  );
}