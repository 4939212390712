import React from 'react';
import { Clock, CheckCircle2 } from 'lucide-react';
import { calculateTimeMetrics } from '../../../../utils/quality/timeMetrics';
import type { QualityIssue } from '../../../../types/quality';

interface TimeMetricsProps {
  issues: QualityIssue[];
}

export function TimeMetrics({ issues }: TimeMetricsProps) {
  const { 
    averageTimeToClose,
    averageTimeOpen,
    longestOpenIssue,
    quickestResolution
  } = calculateTimeMetrics(issues);

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <h3 className="text-lg font-medium text-gray-900 mb-6">Time Analysis</h3>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-6">
          <div>
            <div className="flex items-center space-x-2 mb-2">
              <Clock className="text-blue-600" size={20} />
              <h4 className="text-sm font-medium text-gray-700">Average Time to Close</h4>
            </div>
            <p className="text-2xl font-bold text-gray-900">{averageTimeToClose}</p>
          </div>

          <div>
            <div className="flex items-center space-x-2 mb-2">
              <Clock className="text-yellow-600" size={20} />
              <h4 className="text-sm font-medium text-gray-700">Average Time Open</h4>
            </div>
            <p className="text-2xl font-bold text-gray-900">{averageTimeOpen}</p>
          </div>
        </div>

        <div className="space-y-6">
          <div>
            <div className="flex items-center space-x-2 mb-2">
              <Clock className="text-red-600" size={20} />
              <h4 className="text-sm font-medium text-gray-700">Longest Open Issue</h4>
            </div>
            <p className="text-2xl font-bold text-gray-900">{longestOpenIssue}</p>
          </div>

          <div>
            <div className="flex items-center space-x-2 mb-2">
              <CheckCircle2 className="text-green-600" size={20} />
              <h4 className="text-sm font-medium text-gray-700">Quickest Resolution</h4>
            </div>
            <p className="text-2xl font-bold text-gray-900">{quickestResolution}</p>
          </div>
        </div>
      </div>
    </div>
  );
}