import React, { useState } from 'react';
import { FileDown, Loader2 } from 'lucide-react';

interface ExportButtonProps {
  onExport: () => Promise<boolean>;
}

export function ExportButton({ onExport }: ExportButtonProps) {
  const [isExporting, setIsExporting] = useState(false);

  const handleExport = async () => {
    setIsExporting(true);
    try {
      const success = await onExport();
      if (!success) {
        alert('Failed to export dashboard. Please try again.');
      }
    } catch (error) {
      console.error('Export error:', error);
      alert('Failed to export dashboard. Please try again.');
    } finally {
      setIsExporting(false);
    }
  };

  return (
    <button
      onClick={handleExport}
      disabled={isExporting}
      className={`
        flex items-center space-x-2 px-4 py-2 rounded-lg
        ${isExporting
          ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
          : 'bg-blue-600 text-white hover:bg-blue-700'
        }
        transition-colors
      `}
    >
      {isExporting ? (
        <>
          <Loader2 className="animate-spin" size={20} />
          <span>Exporting...</span>
        </>
      ) : (
        <>
          <FileDown size={20} />
          <span>Export as PNG</span>
        </>
      )}
    </button>
  );
}