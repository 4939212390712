import React from 'react';
import { AlertCircle } from 'lucide-react';
import type { QualityIssue } from '../../../../types/quality';

interface RootCauseAnalysisProps {
  issues: QualityIssue[];
}

export function RootCauseAnalysis({ issues }: RootCauseAnalysisProps) {
  // Placeholder data until root cause is configured
  const rootCauses = {
    'Hardware Failure': 35,
    'Software Bug': 25,
    'Configuration Error': 20,
    'User Error': 15,
    'Unknown': 5,
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <h3 className="text-lg font-medium text-gray-900 mb-6">Root Cause Analysis</h3>
      
      <div className="space-y-4">
        {Object.entries(rootCauses).map(([cause, percentage]) => (
          <div key={cause} className="space-y-1">
            <div className="flex items-center justify-between text-sm">
              <div className="flex items-center space-x-2">
                <AlertCircle size={16} className="text-indigo-500" />
                <span className="text-gray-700">{cause}</span>
              </div>
              <span className="text-gray-600">{percentage}%</span>
            </div>
            <div className="w-full bg-gray-100 rounded-full h-2">
              <div
                className="bg-indigo-500 h-2 rounded-full transition-all duration-300"
                style={{ width: `${percentage}%` }}
              />
            </div>
          </div>
        ))}
      </div>

      <div className="mt-4 p-4 bg-blue-50 rounded-lg">
        <p className="text-sm text-blue-700">
          Root cause analysis will be configurable in future updates.
        </p>
      </div>
    </div>
  );
}